import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import jwt_decode from 'jwt-decode';
import { googleLogout } from '@react-oauth/google';

// assets
import { IconBuilding, IconChartHistogram, IconGraph, IconLogout, IconSettings, IconUsers } from '@tabler/icons';
import getUserInfo, { getAskllyAdmin } from 'services/app.services';
import MenuCard from 'layout/MainLayout/Sidebar/MenuCard';
import axios from 'axios';

// ==============================|| PROFILE MENU ||============================== //

function stringToColor(name) {
  if (!name || name.length === 0) {
    return '#000000';
  }

  let hash = 0;
  let i;

  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name) {
  if (!name) {
    return {
      sx: {
        // Define default styling if needed
      },
      children: '',
    };
  }
  const nameParts = name.split(' ');

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${nameParts[0][0]}${nameParts[1] ? nameParts[1][0] : ''}`,
  };
}


const ProfileSection = ({ isAsklly }) => {
  const theme = useTheme();
  const [wish,setWish] = useState();
  const [user,setUser] = useState();
  const [prof,setProf] = useState();
  const [organization,setOrganization] = useState();
  const date = new Date();
  const h = parseInt(date.getHours());

  const wishHour = () => {
    console.log(typeof h);
    if (h >= 0 && h <= 12) {
      setWish('Good Morning');
    } else if (h >= 12&&h <= 16) {
      setWish('Good Afternoon');
    } else if (h >= 16 && h <=23 ) {
      setWish('Good Evening');
    }
  }
  const getUser = async() => {
    const id = localStorage.getItem('uid')
    const gauth = localStorage.getItem("gauthcred")
    const usr = await getUserInfo(id)
    if(usr){
      setUser(usr)
      const { data } = await axios.post("https://fast.asklly.ai/get/org",{
        email: usr?.email
      })
      setOrganization(data)
      localStorage.setItem("organization",data)
      if(usr?.prof !== ""){
        setProf(usr?.prof)
        localStorage.setItem("image",usr?.prof)
      }else if(gauth){
        const usr = jwt_decode(gauth)
        setUser(usr)
        localStorage.setItem("image",user?.picture)
        setProf(user?.picture)
      }else{
        setProf("")
      }
    }
  }

  useEffect(() => {
    getUser();
    wishHour();
    getOrg()
  }, []);

  const getOrg = async() => {
    try {
      const uid = localStorage.getItem("uid")
      const usr = await getUserInfo(uid)
      const org = localStorage.getItem("organization")
      
    } catch (error) {
      console.log(error)
    }
  }

  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    console.log('Logout');
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const logout = () => {
    localStorage.removeItem('uid')
    if(localStorage.getItem("gauthcred")){
      localStorage.removeItem("gauthcred");
      googleLogout();
    }
    localStorage.removeItem('usr')
    localStorage.removeItem('user')
    localStorage.removeItem('email')
    localStorage.removeItem('organization')
    navigate('/login')
  }

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        icon={prof?
          <Avatar
            src={prof}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer'
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          /> 
          :
          <Avatar
            {...stringAvatar(user&&user?.uname)}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer'
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">{user&&user?.uname || `${user?.given_name} ${user?.family_name}`}, {wish&&wish}</Typography>
                      </Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h5">{organization}</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 2 }}>
                      <MenuCard username= {user&&user?.uname || `${user?.given_name} ${user?.family_name}`} />
                      <Divider />
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 0}
                          onClick={(e)=>{
                            navigate('/teams')
                            handleClose(e)
                          }}
                        >
                          <ListItemIcon>
                            <IconUsers stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Teams</Typography>} />
                        </ListItemButton>

                        {isAsklly&&<ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 0}
                          onClick={(e)=>{
                            navigate('/tenants')
                            handleClose(e)
                          }}
                        >
                          <ListItemIcon>
                            <IconBuilding stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Onboard Tenants</Typography>} />
                        </ListItemButton>}

                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 0}
                          onClick={(e)=>{
                            navigate('/profile')
                            handleClose(e)
                          }}
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                        </ListItemButton>

                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={(e)=>{
                            navigate('/audit')
                            handleClose(e)
                          }}
                        >
                          <ListItemIcon>
                            <IconGraph stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Activity Log</Typography>} />
                        </ListItemButton>

                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={(e)=>{
                            logout()
                            handleClose(e)
                          }}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
