import { Close } from "@mui/icons-material";
import { SwipeableDrawer } from "@mui/material";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { DocumentViewer } from "react-documents";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

const PDFReader = ({file,close,openDrawer,fileName,kbName}) => {  
  const [numPages, setNumPages] = useState(null);
  const [loading,setLoading] = useState(true)
  function onDocumentLoadSuccess({ numPages },doc) {
    console.log(doc)
    setNumPages(numPages);
  }
  return (
    <SwipeableDrawer
      anchor={"right"}
      open={openDrawer}
      onClose={()=>close()}
      // onOpen={()=>setOpenDrawer(true)}
    >
      <div className='md:w-[50vw] w-[80vw] bg-white'>
        <div className='flex items-center justify-between p-6'>
          <h2 className='font-bold text-xl'>{fileName}</h2>
          <button onClick={()=>close()}>
            <Close className='cursor-pointer' />
          </button>
        </div>
        <div className='h-[80vh] p-1'>
          {/* <Document file={file} onLoadError={(err)=>console.log(err)} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} />
            ))}
            </Document> */}
            <DocumentViewer
              viewerUrl={"https://docs.google.com/gview?url=%URL%&embedded=true"}
              url={file}
              loaded={()=>setLoading(false)}
              viewer="url"
            />
        </div>
      </div> 
    </SwipeableDrawer>
  );
};
export default PDFReader;