import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled } from '@mui/system';
import { Button, CircularProgress, Drawer, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TableHead, TextField, Typography } from '@mui/material';
import { IconX, IconSearch } from '@tabler/icons';
import MainCard from 'ui-component/cards/MainCard';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import getUserInfo, { addNewTeam, getKbByName, getMemberRole, getMyTeams, getRoles } from 'services/app.services';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function CustomPaginationActionsTable() {
    const [page, setPage] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    const [search, setSearch] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [teamName, setTeamName] = useState("");
    const [teamMembers, setTeamMembers] = useState([{ email: "", role: [], disabled: true }]);
    const [owner, setOwner] = useState("");
    const [selectedKb, setSelectedKb] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [teams, setTeams] = useState([]);
    const [roles, setRoles] = useState([]);
    const [kbName, setKbName] = useState([]);
    const [access, setAccess] = useState(false);
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const [loadingTeam, setLoadingTeam] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedTeamId(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const nav = useNavigate();

    const get_roles = async () => {
        const role = await getRoles();
        setRoles(role.roles);
    };

    const get_kb = async () => {
        const uid = localStorage.getItem("uid");
        const form = new FormData();
        const user = await getUserInfo(uid);
        const email = localStorage.getItem("email");
        form.append("email", user.email);
        const organization = localStorage.getItem("organization");
        form.append("organization", organization);
        const acc = await getMemberRole(form);
        const kb = await getKbByName(form);
        let acces = acc.role.role.split(",")?.map(i => i === "Admin" || i === "Create Teams");
        setAccess(acces[0]);
        setKbName(kb?.kb);
    };

    const getTeams = async () => {
        setLoadingTeam(true);
        const uid = localStorage.getItem("uid");
        const form = new FormData();
        const user = await getUserInfo(uid);
        const email = localStorage.getItem("email");
        form.append("email", user?.email);
        const organization = localStorage.getItem("organization");
        form.append("organization", organization);
        const data = await getMyTeams(form);
        if (data.teams.length > 0) {
            setTeams(data.teams);
        } else {
            setTeams([]);
        }
        setLoadingTeam(false);
    };

    useEffect(() => {
        const uid = localStorage.getItem("uid");
        getDetails(uid);
        getTeams();
        get_roles();
        get_kb();
    }, []);

    const getDetails = async (uid) => {
        const data = await getUserInfo(uid);
        setOwner(data);
        setTeamMembers([{ email: data.email, role: ["Admin"], disabled: true }]);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (search.length || teams.length)) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddMemberInput = () => {
        setTeamMembers((prevMembers) => [...prevMembers, { email: "", role: [], disabled: false }]);
    };

    const handleMemberInputChange = (index, property, value) => {
        const newMembers = [...teamMembers];
        if (property === "role") {
            const selectedRoles = Array.isArray(value) ? value : [value];
            newMembers[index][property] = selectedRoles;
        } else {
            newMembers[index][property] = value;
        }
        setTeamMembers(newMembers);
    };

    const handleRemoveMember = (index) => {
        if(index === 0){
            return
        }
        const newMembers = [...teamMembers];
        newMembers.splice(index, 1); // Remove the member at the specified index
        setTeamMembers(newMembers);
    };

    const handleAddMember = async () => {
        const uid = localStorage.getItem("uid");
        const user = await  getUserInfo(uid)
        const email = user?.email;
        const organization = localStorage.getItem("organization");
        const form = new FormData();
        form.append("uid", uid);
        form.append("organization",organization)
        form.append("team_contact_email", email);
        form.append("email_addresses", teamMembers.map(member => member.email).join(","));
        form.append("knowledge_base_limit", selectedKb);
        form.append("owner", email); // Assuming the owner is the same as the email
        form.append("team_name", teamName);
        form.append("membersRoles", JSON.stringify(teamMembers.map(member => ({ email: member.email, role: member.role }))));
        
        setLoading(true);
        const data = await addNewTeam(form);
        setLoading(false);
        if (data.message === "New Team has been Created") {
            toast.success(data.message);
            setTeamMembers([{ email: "", role: [], disabled: true }]);
            setTeamName("");
            setDrawerOpen(false);
            getTeams();
        } else {
            toast.error(data.message);
        }
    };

    const handleSelectionChange = (e) => {
        const selectedValues = e.target.value;
        if (selectedValues?.includes('selectAll')) {
            const allKbIds = kbName.map((item) => item.kb_id);
            setSelectedKb(allKbIds);
        } else {
            const selectedIds = selectedValues.map((name) => {
                const selectedItem = kbName.find(item => item.kb_name === name);
                return selectedItem ? selectedItem.kb_id : null;
            }).filter(id => id !== null);
            setSelectedKb(selectedIds);
        }
    };

    const selectedKbNames = selectedKb.map(id => {
        const item = kbName.find(kb => kb.kb_id === id);
        return item ? item.kb_name : null;
    }).filter(name => name !== null);

    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);

        const searchResults = teams.filter((team) =>
            team?.team.team_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearch(searchResults);
    };

    return (
        <MainCard>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography sx={{mb:3}} variant='h3'>Manage Teams</Typography>
                {access && <Button color='secondary' variant='outlined' onClick={()=>setOpenDrawer(true)}>Add Team</Button>}
            </Stack>
            <TextField
                placeholder="Search Team"
                fullWidth
                value={searchTerm}
                onChange={handleSearch}
                color='secondary'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconSearch />
                        </InputAdornment>
                    ),
                    endAdornment: searchTerm.length > 0 && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear search"
                                onClick={() => setSearchTerm('')}
                                edge="end"
                            >
                                <IconX />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Team</StyledTableCell>
                            <StyledTableCell>Knowledge Hub Access</StyledTableCell>
                            <StyledTableCell>Owner</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? (search.length > 0 ? search : teams).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : search.length > 0 ? search : teams
                        ).map((team) => (
                            <StyledTableRow key={team?.team.id} >
                                <StyledTableCell component="th" scope="row" className='hover:underline cursor-pointer' onClick={()=>nav(`/teams/${team?.team.id}`)}>
                                    {team?.team.team_name}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {team.kb_name}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {team?.team?.owner}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {team?.team?.team_contact_email}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={4} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={4}
                                count={search.length > 0 ? search.length : teams.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Drawer
                anchor={"right"}
                open={openDrawer}
                onClose={()=>{
                    setOpenDrawer(false)
                    setSelectedKb([])
                }}
            >
                <Box sx={{width:"50vw",padding:5}}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant='h3'>Add Team</Typography>
                        <IconButton onClick={()=>{
                            setOpenDrawer(false)
                            setSelectedKb([])
                        }}>
                            <IconX />
                        </IconButton>
                    </Stack>
                    <Stack sx={{mt:7}}>
                        <Typography variant='h4'>Team Name</Typography>
                        <TextField placeholder='Team name Here' value={teamName} onChange={(e)=>setTeamName(e.target.value)} variant='outlined' color='secondary' sx={{mt:1}} />
                    </Stack>
                    <Stack sx={{mt:2}}>
                        <Typography variant='h4'>Owner</Typography>
                        <TextField placeholder='Team name Here' variant='outlined' value={owner.uname} disabled color='secondary' sx={{mt:1}} />
                    </Stack>
                    <Stack sx={{mt:2}}>
                        <Typography variant='h4'>Choose Knowledge Hub</Typography>
                        <FormControl variant="outlined" sx={{ mt: 2 }}>
                            <InputLabel>Knowledge Hub</InputLabel>
                            <Select
                                multiple
                                value={selectedKbNames}
                                onChange={handleSelectionChange}
                                label="Knowledge Hub"
                                color='secondary'
                            >
                                {kbName?.map((i,index)=>(<MenuItem value={i?.kb_name} key={index}>{i?.kb_name}</MenuItem>))}
                                <MenuItem value={'selectAll'}>Select All Knowledge Hub</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack sx={{overflow:"auto", height:"390px"}}>
                        <Typography variant='h4' sx={{ my:2 }}>Add Members</Typography>
                        {teamMembers.map((member, index) => (
                            <Stack key={index} direction="row" justifyContent="space-between">
                                <OutlinedInput
                                    key={index}
                                    disabled={member.disabled}
                                    placeholder={`Member ${index + 1} email Here`}
                                    variant='outlined'
                                    color='secondary'
                                    sx={{ mt: 1 }}
                                    value={member.email}
                                    onChange={(e) => handleMemberInputChange(index, "email", e.target.value)}
                                    endAdornment={
                                        <InputAdornment>
                                            <IconButton onClick={()=>handleRemoveMember(index)}>
                                                <IconX />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormControl variant="outlined" sx={{ mt: 1, ml: 1, width: "150px" }}>
                                    <InputLabel>Role</InputLabel>
                                    <Select
                                        multiple
                                        value={member.role}
                                        disabled={member.disabled}
                                        onChange={(e) => handleMemberInputChange(index, "role", e.target.value)}
                                        label="Role"
                                        color='secondary'
                                    >
                                        {roles?.map((i)=>(<MenuItem value={i?.roles} key={i?.id}>{i?.roles}</MenuItem>))}
                                    </Select>
                                </FormControl>
                                <Button color='secondary' onClick={handleAddMemberInput}>Add Member</Button>
                            </Stack>
                        ))}
                    </Stack>
                    <Stack>
                        <Button onClick={handleAddMember} variant='outlined' color='secondary' disabled={loading}>{loading?<CircularProgress color='secondary' />:"Add Team"}</Button>
                    </Stack>
                </Box>
            </Drawer>
        </MainCard>
    );
}