import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import ChatSection from './ChatSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { ChatBubbleOutline, Share } from '@mui/icons-material';
import { useEffect } from 'react';
import getUserInfo, { addkbqa, getAskllyAdmin } from 'services/app.services';
import Home from '@mui/icons-material/Home';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import axios from 'axios';

import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { Stack } from '@mui/material';

import AssignmentIcon from '@mui/icons-material/Assignment';
import HubIcon from '@mui/icons-material/Hub';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ListIcon from '@mui/icons-material/List';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const ChatHeader = ({ handleLeftDrawerToggle,showHome,toogleData,navi,bot,handleSelectChange,kbs = [],chatId, shareChat }) => {
  const nav = useNavigate()
  const theme = useTheme();

  const [isAsklly,setIsAsklly] = useState(false);

  const getAsklly = async() => {
    try {
      const uid = localStorage.getItem("uid")
      const usr = await getUserInfo(uid)
      const org = localStorage.getItem("organization")
      if(!org){
        const { data } = await axios.post("https://fast.asklly.ai/get/org",{
          email: usr?.email
        })
        const body = {
          email: usr.email,
          organization: data
        }
        const orgn  = await getAskllyAdmin(body)
        setIsAsklly(orgn)
      }else{
        const body = {
          email: usr.email,
          organization: org
        }
        const data  = await getAskllyAdmin(body)
        setIsAsklly(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    getAsklly().then()
    return ()=>{
      setIsAsklly(false)
    }
  },[])

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 270,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={toogleData}
            color="inherit"
          >
            {<IconMenu2 stroke={1.5} size="1.3rem" />}
          </Avatar>
        </ButtonBase>
      </Box>

      {/* New Chat */}
      <Box sx={{ flexGrow: 1, ml:1 }}>
        <Stack direction={'row'} alignItems={'center'} sx={{ml:'2'}}>
          <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              onClick={toogleData}
              color="inherit"
            >
              <Tooltip placement='bottom' title="New Chat" arrow>
                  <IconButton
                    onClick={navi}
                  >
                    <AddCommentIcon fontSize='medium' className='text-[#5E35B1]'/>
                  </IconButton>
              </Tooltip>
            </Avatar>
          </ButtonBase>
            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="knowledge-hub-select-label">Select Knowledge Hub</InputLabel>
                <Select
                  labelId="knowledge-hub-select-label"
                  multiple
                  value={bot}
                  color="secondary"
                  onChange={handleSelectChange}
                  label="Select Knowledge Hub"
                  renderValue={(selected) => kbs.filter(kb => selected.includes(kb.kb_id)).map(kb => kb.kb_name).join(', ')}
                >
                  <MenuItem value="" disabled>
                      Select Knowledge Hub
                  </MenuItem>
                  {kbs?.length > 0 && kbs.map((kb) => (
                    <MenuItem key={kb?.kb_id} value={kb?.kb_id}>
                      {kb?.kb_name}
                    </MenuItem>
                  ))}
                </Select>
            </FormControl>
            {chatId&&<Tooltip placement='bottom' title="Share Chat">
              <IconButton onClick={shareChat}>
                <Share fontSize='medium' className='text-[#5E35B1]'/>
              </IconButton>
            </Tooltip>}
        </Stack>
      </Box>
      {/* Home */}
      <Box sx={{ ml: 1, mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle?handleLeftDrawerToggle:()=>nav("/welcome")}
            color="inherit"
          >
            
            {showHome?<Home stroke={1.5} size="1.3rem" />:<IconMenu2 stroke={1.5} size="1.3rem" />}
          </Avatar>
        </ButtonBase>
      </Box>

      {/* Available Assistants */}
      <Box sx={{ ml: 1, mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              onClick={handleLeftDrawerToggle?handleLeftDrawerToggle:()=>nav("/mybots")}
              color="inherit"
            >
              <Tooltip placement='bottom' title="Available Assistants" arrow>
                <ListIcon stroke={1.5} size="1.3rem" />
              </Tooltip>
            </Avatar>
        </ButtonBase>
      </Box>


      {/* Create Assistant */}
      <Box sx={{ ml: 1, mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              onClick={handleLeftDrawerToggle?handleLeftDrawerToggle:()=>nav("/train")}
              color="inherit"
            >
              <Tooltip placement='bottom' title="Create Assistant " arrow>
                <SmartToyIcon stroke={1.5} size="1.3rem" />
              </Tooltip>
            </Avatar>
        </ButtonBase>
      </Box>


      {/* Knowledge Hub */}
      <Box sx={{ ml: 1, mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              onClick={handleLeftDrawerToggle?handleLeftDrawerToggle:()=>nav("/kb")}
              color="inherit"
            >
              <Tooltip placement='bottom' title="Knowledge Hub" arrow>
                <HubIcon stroke={1.5} size="1.3rem" />
              </Tooltip>
            </Avatar>
        </ButtonBase>
      </Box>

      <NotificationSection />
      <ProfileSection isAsklly={isAsklly} />
    </>
  );
};

ChatHeader.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default ChatHeader;
