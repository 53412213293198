// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { useState } from 'react';
import { useEffect } from 'react';
import getUserInfo, { getMemberRole } from 'services/app.services';
import pages from 'menu-items/pages';
import team_page from 'menu-items/team_page';
import chat_page from 'menu-items/chat_pages';
import utilities from 'menu-items/utilities';
import bot_pages from 'menu-items/bot_pages';
import { useNavigate } from 'react-router';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [menu,setMenu] = useState([])
  const nav = useNavigate()
  useEffect(()=>{
    getUserRole()
  },[])
  async function getUserRole(){
    const uid = localStorage.getItem("uid")
    const user = await getUserInfo(uid)
    // console.log(user)
    const form = new FormData()
    form.append("email",user?.email)
    const role = await getMemberRole(form)
    if(!role?.role){
      nav("/no-org")
    }
    console.log(role)
    const finalRole = role?.role?.role?.split(",")
    localStorage.setItem("organization",role?.role?.organization)
    // console.log(finalRole)
    finalRole?.map((i)=>{
      if (i === "Admin"){
        console.log("dashboard,pages,bot_pages,utilities,team_page,chat_page")
        return setMenu([pages,chat_page,bot_pages,team_page,utilities])
      }else if(i === "Create Knowledge Base"){
        console.log("pages")
        setMenu((menu)=>[...menu,pages])
      }else if(i === "Only Query Knowledge Base"){
        console.log("only chat")
        setMenu((menu)=>[...menu,chat_page])
      }else if(i === "Create Teams"){
        setMenu((menu)=>[...menu,team_page])
      }
    })

  }
  const navItems = menu.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
