import { MoreHoriz, MoreVert } from '@mui/icons-material'
import { Box, Button, Checkbox, CircularProgress, Drawer, FormControl, FormLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, tableCellClasses } from '@mui/material'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { Stack, styled } from '@mui/system'
import { IconArrowBadgeLeft, IconArrowLeft, IconEdit, IconSearch, IconTrash, IconUserPlus, IconX } from '@tabler/icons'
import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams, useRoutes } from 'react-router'
import MainCard from 'ui-component/cards/MainCard'
import Menu from '@mui/material/Menu';
import getUserInfo, { addNewTeam, deleteMember, deleteTeam, editingMember, editingTeam, getKbByName, getMemberRole, getRoles, getTeamAccess, getTeamMembers, updateTeam } from 'services/app.services'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import deleteImg from "assets/images/Inbox cleanup-rafiki.png"

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#F2F2F2",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

function createData(name, email, role) {
    return { name, email, role };
}

function Members() {
    // const [rows,setRows] = useState([])
    const { id } = useParams()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [search, setSearch] = useState([]);
    const [openDrawer,setOpenDrawer] = useState(false)
    const [teamMembers, setTeamMembers] = React.useState([{ email: "", role: [] }]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [members,setMembers] = useState([])
    const [teamKb,setTeamKb] =  useState("")
    const [teamName,setTeamName] = useState("")
    const [roles,setRoles] = useState([])
    const [access,setAccess] = useState(false)
    const [org,setOrganization] = useState("")
    const [editMember,setEditMember] = useState(false)
    const [editMemberDetail,setEditMemberDetail] = useState({})
    const [loading,setLoading] = useState(false)
    const [loading2,setLoading2] = useState(false)
    const [selectedMemberId,setSelectedMemberId] = useState("")
    const [team,setTeam] = useState({})
    const [drawerOpen,setdrawerOpen] = useState(false)
    const [selectedKb,setSelectedKb] = useState([])
    const [kbName,setKbName] = useState([])
    const [ok,setOk] = useState(false)
    const [deleted,setDelete] = useState(false)

    const fetchKbName = async() => {
        const uid = localStorage.getItem("uid");
        const usr = JSON.parse(localStorage.getItem("user"))
        const form = new FormData()
        const user = await getUserInfo(uid)
        form.append("email",user?.email)
        const organization = localStorage.getItem("organization")
        form.append("organization",organization)
        const data = await getKbByName(form)
        const form2 = new FormData()
        form2.append("email",user?.email)
        const role = await getMemberRole(form2)
        setOrganization(organization)
        setKbName(data?.kb);
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const get_roles = async() =>{
        const role = await getRoles();
        setRoles(role.roles)
    }
    const getMembers = async() =>{
        setLoading2(true)
        const form = new FormData()
        form.append("team_id",id)
        const data = await getTeamMembers(form)
        setTeamKb(data?.kb_name)
        setTeamName(data?.team?.team_name)
        setSelectedKb(data?.team?.knowledge_base_limit?.split(','));
        setTeam(data?.team)
        if(data.members.length > 0){
            setMembers(data.members)
        }else{
            setMembers([])
        }
        setLoading2(false)
    }

    const getAccess = async() => {
        const form = new FormData()
        form.append("team_id",id)
        const uid = localStorage.getItem("uid")
        const user = await getUserInfo(uid)
        form.append("email",user?.email)
        const haveAccess = await getTeamAccess(form)
        setAccess(haveAccess)
    }

    useEffect(()=>{
        getMembers()
        get_roles()
        fetchKbName()
        getAccess()
    },[])

    const nav = useNavigate()

    const handleClickEdit = (event, memberId) => {
        setAnchorEl(event.currentTarget);
        setSelectedMemberId(memberId);
    };
      

    // Avoid a layout jump when reaching the last page with empty rows.

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddMemberInput = () => {
        setTeamMembers((prevMembers) => [...prevMembers, { email: "", role: [] }]); // Add a new member object
    };

    const handleMemberInputChange = (index, property, value) => {
        const newMembers = [...teamMembers];
        if (property === "role") {
            const selectedRoles = Array.isArray(value) ? value : [value];
            newMembers[index][property] = selectedRoles;
        } else {
            newMembers[index][property] = value;
        }
        setTeamMembers(newMembers);
    };

    const handleSearch = (searchText) => {
        setSearchTerm(searchText);
        const searchResult = members.filter((value) => 
            value.member_name.toLowerCase().includes(searchText.toLowerCase()) ||
            value.role.toLowerCase().includes(searchText.toLowerCase())
        );
        setSearch(searchResult);
        setPage(0);
    };

    const handleRemoveMember = (index) => {
        const newMembers = [...teamMembers];
        newMembers.splice(index, 1); // Remove the member at the specified index
        setTeamMembers(newMembers);
    };

    const membersDelete = async() =>{
        const uid = localStorage.getItem("uid")
        const form = new FormData()
        const org = localStorage.getItem("organization")
        form.append("organization",org)    
        form.append("member_id",selectedMemberId)
        form.append("team_name", teamName)
        form.append("uid",uid)
        const data = await deleteMember(form)
        getMembers()
        handleClose()
        toast.success("Member deleted succesfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const membersEdit = async () => {
        const member = members.find(i => i.id === selectedMemberId);
        if (member) {
          setEditMemberDetail({
            ...member,
            oldemail: member?.member_name,
            role: member?.role.split(",") || []
          });
        }
        handleClose();
        setEditMember(true);
      };

    const handleEmailChange = (event) => {
        setEditMemberDetail((prevDetails) => ({
            ...prevDetails,
            member_name: event.target.value
        }));
    };

    const handleRoleChange = (event) => {
        const {
            target: { value },
        } = event;
        setEditMemberDetail((prevDetails) => ({
            ...prevDetails,
            role: value,
        }));
    };

    const handleEditMember = async() => {
        try {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!editMemberDetail?.member_name) {
                toast.error("Please fill the email fields.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }

            if(!editMemberDetail?.role?.length >= 1){
                toast.error("Please choose the role.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }

            if (!emailPattern.test(editMemberDetail?.member_name)) {
                toast.error("Enter an correct email to continue", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }

            setLoading(true)
            const formData = new FormData()
            formData.append("email",editMemberDetail?.member_name)
            const roles = editMemberDetail?.role?.join(",")
            formData.append("role",roles)
            formData.append("oldemail",editMemberDetail?.oldemail)
            await editingMember(formData,setEditMember)
            getMembers(id)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const AddNewMember = async() => {
        try {
            const isAllMembersNotEmpty = teamMembers.every(
                (member) => member.email.trim() !== '' && member.role.join(" ") !== ''
            );

            const isValidEmailFormat = teamMembers.every(member => {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailPattern.test(member.email.trim());
            });

            if(!isAllMembersNotEmpty){
                return toast.error("Add Atleast one with role member to continue", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

            if(!isValidEmailFormat){
                return toast.error("Enter an valid email to continue", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            setLoading(true)
            const form = new FormData()
            const uid = localStorage.getItem("uid")
            form.append("team_id",id)
            form.append("uid",uid)
            const formattedMembersRoles = teamMembers.map(({ email, role }) => ({ email, role: role.join(',') }));
            form.append("membersRoles", JSON.stringify(formattedMembersRoles));
            const organization = localStorage.getItem("organization")
            form.append("organization",organization)
            const data = await updateTeam(form)
            toast.success("Added Members Succesfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            getMembers(id)
            setTeamMembers([{ email: "", role: [] }])
            setLoading(false)
            setOpenDrawer(false)
        } catch (error) {
            console.log(error)  
        }
    }

    const handleClear = () => {
        setSelectedKb([])
    }

    const handleEditTeam = async() => {
        if(team?.team_name.length < 3){
            return toast.error("Team name should be more than 3 characters long", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        const form = new FormData()
        form.append("team_id",id)
        form.append("team_name",team?.team_name)
        form.append("knowledge_base_limit",selectedKb.join(","))
        await editingTeam(form,setdrawerOpen,handleClear)
        await getMembers(id)
    }

    const teamDelete = async() => {
        const form = new FormData()
        const uid = localStorage.getItem("uid")
        const org = localStorage.getItem("organization")
        form.append("organization",org)    
        form.append("team_id",id)
        form.append("uid",uid)
        const data = await deleteTeam(form)
        nav("/teams")
    }

    const handleSelectionChange = (e) => {
        const selectedValues = e.target.value;
        if (selectedValues?.includes('selectAll')) {
            const allKbIds = kbName.map((item) => item.kb_id);
            setSelectedKb(allKbIds);
        } else {
            const selectedIds = selectedValues.map((name) => {
                const selectedItem = kbName.find(item => item.kb_name === name);
                return selectedItem ? selectedItem.kb_id : null;
            }).filter(id => id !== null);
            setSelectedKb(selectedIds);
        }
    };

    const selectedKbNames = selectedKb.map(id => {
        const item = kbName.find(kb => kb.kb_id === id);
        return item ? item.kb_name : null;
    }).filter(name => name !== null);

    return (
        <MainCard>
            <Stack direction={"row"} alignItems={"center"}>
                <IconButton
                    onClick={()=>nav("/teams")}
                >
                    <IconArrowLeft />
                </IconButton>
                
                <Typography variant='h3'>Members</Typography>
            </Stack>
            {loading2?
                <Stack height={"50vh"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress color='secondary'/>
                    <Typography sx={{mt:4}} variant='h3'>Loading ...</Typography>
                </Stack>
            :<Box sx={{mt: 4, mb: 3}}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"column"} sx={{width:"100%"}}>
                        <Typography variant='h3' sx={{mb: 1}}>{teamName}</Typography>
                        <Typography variant='h5' className="w-[70%]">Knowledge Hub - {teamKb}</Typography>
                        <Typography variant='h5'>Organization - {org}</Typography>
                    </Stack>
                   {access&&
                    <Stack direction={"row"}>
                        <Tooltip placement='top' title="Add Member">
                            <IconButton className='mr-2' onClick={()=>setOpenDrawer(true)}>
                                    <IconUserPlus className='text-[#5E35B1]'/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement='top' title="Edit Team">
                            <IconButton className='mr-2' onClick={()=>setdrawerOpen(true)}>
                                    <IconEdit className='text-[#5E35B1]'/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement='top' title="Delete Team">
                            <IconButton className='mr-2' onClick={()=>setDelete(true)}>
                                    <IconTrash className='text-red-500'/>
                            </IconButton>
                        </Tooltip>
                    </Stack>}
                </Stack>
                <Box sx={{mt: 2, mb: 2}}>
                    <OutlinedInput
                        fullWidth
                        color='secondary'
                        placeholder='Search'
                        value={searchTerm}
                        onChange={(e) => handleSearch(e.target.value)}
                        startAdornment={
                            <InputAdornment>
                                <IconSearch stroke={1.5} size="1rem" />
                            </InputAdornment>
                        }
                    />
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 700, overflowX: "auto"}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell sx={{fontWeight: 600}}>Members</TableCell> */}
                                <StyledTableCell sx={{fontWeight: 600}}>Member</StyledTableCell>
                                <StyledTableCell sx={{fontWeight: 600}}>Status</StyledTableCell>
                                <StyledTableCell sx={{fontWeight: 600}}>Role</StyledTableCell>
                                <StyledTableCell sx={{fontWeight: 600}}>More</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {members.length <= 0 ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">
                                            No Members Were Added
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : 
                            (search.length !== 0 ? search : members).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <StyledTableRow>
                                    {/* <TableCell>{row.name}</TableCell> */}
                                    <TableCell>{row.member_name}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>{row.role}</TableCell>
                                    <TableCell>
                                        {access && <>
                                            {row.member_name === team?.team_contact_email ?
                                                "Owner cannot be edited or deleted"
                                            :<>
                                            <IconButton onClick={(e) => handleClickEdit(e, row.id)}>
                                                <MoreHoriz />
                                            </IconButton>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem onClick={()=>membersEdit(row.id)}><IconEdit className='mr-2 text-blue-500' />Edit User</MenuItem>
                                                <MenuItem onClick={()=>membersDelete(row.id)}><IconTrash className='mr-2 text-red-500' />Delete User</MenuItem>
                                            </Menu>
                                            </>}
                                        </>}
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
                                    colSpan={4}
                                    count={search.length || members.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                        'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>}
            <Drawer
                open={openDrawer}
                onClose={()=>setOpenDrawer(false)}
                anchor='right'
            >
                <Box sx={{minWidth:"40vw",padding:5}}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant='h3'>Add Members</Typography>
                        <IconButton onClick={()=>setOpenDrawer(false)}>
                            <IconX />
                        </IconButton>
                    </Stack>
                    <Stack sx={{overflow:"auto", mt:2, height:"300px"}}>
                        {teamMembers.map((member, index) => (
                            <Stack key={index} direction="row" justifyContent="space-between">
                                <OutlinedInput
                                    key={index}
                                    placeholder={`Member ${index + 1} email Here`}
                                    variant='outlined'
                                    color='secondary'
                                    sx={{ mt: 1 }}
                                    value={member.email}
                                    onChange={(e) => handleMemberInputChange(index, "email", e.target.value)}
                                    endAdornment={
                                        <InputAdornment>
                                            <IconButton onClick={()=>handleRemoveMember(index)}>
                                                <IconX />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormControl variant="outlined" sx={{ mt: 1, ml: 1, width: "150px" }}>
                                    <InputLabel>Role</InputLabel>
                                    <Select
                                        multiple
                                        value={member.role}
                                        onChange={(e) => handleMemberInputChange(index, "role", e.target.value)}
                                        label="Role"
                                        color='secondary'
                                    >
                                        {roles?.map((i)=>(<MenuItem value={i?.roles} key={i?.id}>{i?.roles}</MenuItem>))}
                                    </Select>
                                </FormControl>
                                <Button color='secondary' onClick={handleAddMemberInput}>Add</Button>
                            </Stack>
                        ))}
                    </Stack>
                    <Stack sx={{mt: 2}}>
                        <Button onClick={AddNewMember} disabled={loading} variant='outlined' color='secondary'>{loading?<CircularProgress color='secondary' />:"Add Members"}</Button>
                    </Stack>
                </Box>
            </Drawer>
            <Drawer
                open={editMember}
                onClose={()=>setEditMember(false)}
                anchor='right'
            >
                <Box sx={{minWidth:"40vw",padding:5}}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant='h3'>Edit Member</Typography>
                        <IconButton onClick={()=>setEditMember(false)}>
                            <IconX />
                        </IconButton>
                    </Stack>
                    <Stack sx={{overflow:"auto", mt:7, height:"300px"}}>
                        <FormLabel htmlFor='email'>
                            <Typography variant='h4'>
                                Member Email
                            </Typography>
                        </FormLabel>
                        <OutlinedInput
                            id="email"
                            variant='outlined'
                            color='secondary'
                            sx={{ mt: 1 }}
                            onChange={handleEmailChange}
                            value={editMemberDetail?.member_name}
                        />
                        <FormLabel htmlFor='email' sx={{mt:5}}>
                            <Typography variant='h4'>
                                Member Role
                            </Typography>
                        </FormLabel>
                        <Select
                            multiple
                            value={editMemberDetail?.role}
                            onChange={handleRoleChange}
                            color='secondary'
                        >
                            {roles?.map((i)=>(<MenuItem value={i?.roles} key={i?.id}>{i?.roles}</MenuItem>))}
                        </Select>
                    </Stack>
                    <Stack sx={{mt: 2}}>
                        <Button onClick={handleEditMember} disabled={loading} variant='outlined' color='secondary'>{loading?<CircularProgress color='secondary' />:"Update"}</Button>
                    </Stack>
                </Box>
            </Drawer>
            <Drawer
                open={drawerOpen}
                anchor='right'
                onClose={() => {
                    setdrawerOpen(false)
                }}
            >
                <div className='md:w-[50vw] w-[80vw] bg-white'>
                    <div className='mt-5 p-7'>
                        <div className='flex justify-between items-center'>
                            <Typography variant='h3'>Edit Team</Typography>
                            <IconButton onClick={() => {
                                setdrawerOpen(false)
                            }}>
                                <IconX />
                            </IconButton>
                        </div>
                        <Stack sx={{ mt: 7 }}>
                            <Typography variant='h4'>Team Name</Typography>
                            <TextField
                                placeholder='Team name Here'
                                value={team?.team_name}
                                onChange={(e) => setTeam({...team,team_name:e.target.value})}
                                variant='outlined'
                                color='secondary'
                                sx={{ mt: 1 }}
                            />
                        </Stack>
                        <Stack sx={{ mt: 2 }}>
                            <Typography variant='h4'>Owner</Typography>
                            <TextField
                                placeholder='Team name Here'
                                variant='outlined'
                                value={team?.owner}
                                disabled
                                color='secondary'
                                sx={{ mt: 1 }}
                            />
                        </Stack>
                        <Stack sx={{ mt: 2 }}>
                            <Typography variant='h4'>Knowledge Hub Limit</Typography>
                            <FormControl variant="outlined" sx={{ mt: 2 }}>
                                <InputLabel>Knowledge Hub</InputLabel>
                                <Select
                                    multiple
                                    value={selectedKbNames}
                                    onChange={handleSelectionChange}
                                    label="Knowledge Hub"
                                    color='secondary'
                                    sx={{ minWidth: 200 }}
                                >
                                    {kbName?.map((i,index)=>(<MenuItem value={i?.kb_name} key={index}>{i?.kb_name}</MenuItem>))}
                                    <MenuItem value={true}>Select All Knowledge Hub</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack sx={{mt:10}}>
                            <Button onClick={handleEditTeam} variant='outlined' color='secondary'>Update</Button>
                        </Stack>
                    </div>
                </div>
            </Drawer>
            <Modal
                open={deleted}
                onClose={() => {
                    setOk(false);
                    setDelete(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{}}
            >
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div className='bg-white h-[45vh] md:w-[30vw] w-[90%] p-2 rounded-md flex flex-col justify-center items-center'>
                        <img src={deleteImg} className='w-40' />
                        <div className='flex flex-col justify-center items-start'>
                            <Typography variant='h3' sx={{ mx: 1 }}>Delete team</Typography>
                            <div className='flex items-center mt-2'>
                                <Checkbox color='secondary' value={ok} onClick={() => { setOk(!ok); }} />
                                <p className='font-semibold'>I Understand that deleting team cannot be recovered</p>
                            </div>
                            <div className='flex items-center mt-2'>
                                <Button color='secondary' sx={{ mr: 2 }} onClick={() => {
                                    setOk(false);
                                    setDelete(false);
                                }}>Cancel</Button>
                                <Button variant='outlined' color='error' disabled={ok === false || loading === true}
                                    onClick={teamDelete}
                                >{loading?<CircularProgress color='error' />:"Delete"}</Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </MainCard>
    )
}

export default Members