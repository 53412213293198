import axios from 'axios';
import {toast} from 'react-toastify'

// const URL = "http://localhost:8000"
const URL = "https://fast.asklly.ai"

const dateObj = new Date
const day = dateObj.getDate().toString()
const month = dateObj.getMonth().toString()
const year = dateObj.getFullYear().toString()


export async function createBot(urls,bot,email,uid){
    try {
        const headers = {
            'X-User-Id':uid
        }
        const { data } = await axios.post(`${URL}/create_bot`,{urls:urls,botname:bot,mainurl:email,date:day+month+year},{headers})
        toast(data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return data;
    } catch (error) {
        console.error(error);
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function getUrls(url,uid){
    try {
        const headers = {
            'X-User-Id':uid
        }
        const { data } = await axios.post(`${URL}/post_url`,{url:url},{headers})
        return data
    } catch (error) {
        console.error(error)
    }
}

export async function createBotQa(bot,qaData,uid){
    try {
        const headers = {
            'X-User-Id':uid
        }
        const { data } = await axios.post(`${URL}/create_bot_qa`,{botname:bot,ownerid:1,questions:qaData,date:day+month+year},{headers})
        
        toast(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return data;
    } catch (error) {
        console.log(error)
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
    }
}

export async function createBotText(bot,text,uid){
    try {
        const headers = {
            'X-User-Id':uid
        }
        const {data} = await axios.post(`${URL}/create_bot_text`,{botname: bot,text: text,date:day+month+year},{headers})
        
        toast(data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function createBotUpload(bot,selectedFile,uid){
    try {
        const formData = new FormData();
        for(const file of selectedFile) {
            formData.append("files", file);
        }    
        formData.append("botname", bot);
        formData.append("date",day+month+year)
        const { data } = await axios.post(`${URL}/upload_files`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-User-Id":uid
          },
        });
        ;
        toast(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return data
    } catch (error) {
        console.error(error);
        toast.error("Bot not created", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    }
}

export async function createApi(body){
    try {
        const { data } = await axios.post(`${URL}/update_bot`, body);
        toast("Bot updated succesfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        return data.data;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteApi(body,uid){
    try {
        const headers = {
            'X-User-Id':uid
        }
        const { data } = await axios.post(`${URL}/delete_bot`, body,{headers});
        ;
        toast("Bot deleted Succesfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return data.data;
    } catch (error) {
        console.error(error);
    }
}

export async function fetchBots(uid){
    try {
        if(!uid) return alert("no uid")
        const headers = {
            'X-User-Id':uid
        }
        const { data } = await axios.get(`${URL}/all_bots`,{headers})
        const combined = [].concat(...data)
        return combined
    } catch (error) {
        console.log(error)
    }
}

export async function signup(fullname,email,password,gauth,nav){
    try {
        const body = {
            email:email, 
            password:password,
            fullname:fullname,
            gauth:gauth
        }
        const { data } = await axios.post(`${URL}/create_user`,body)
        ;
        localStorage.setItem("ob",true)
        if(nav){
            toast("Check your inbox for otp", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            localStorage.setItem('email',email);
            nav('/otp')
        }
        return data
    } catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(`${error.response.data.detail || "There was an error in Signing up"}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
}

export async function verifyOtp(email,otp,nav){
    try {
        const body = {
            email:email, 
            otp:otp
        }
        const { data } = await axios.post(`${URL}/verify-otp`,body)
        ;
        localStorage.setItem("ob",true)
        if(nav){
            localStorage.setItem('uid',data.uid)
            localStorage.setItem('user',JSON.stringify(data.user))
            nav('/welcome')
        }
        return data
    } catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(`${error.response.data.detail }`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        } else {
            toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
}

export async function resendOtp(email,nav){
    try {
        const body = {
            email:email
        }
        const { data } = await axios.post(`${URL}/resend-otp`,body)
        ;
        toast("Otp Sent", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return data
    } catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(`${error.response.data.detail }`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
}

export async function login(email,password,nav){
    try {
        const body = {
            email:email, 
            password:password
        }
        const { data } = await axios.post(`${URL}/login_user`,body)
        ;
        if(data.status_code){
            return toast.error(data.detail, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        localStorage.setItem('uid',data.uid)
        localStorage.setItem('user',JSON.stringify(data.user))
        nav('/welcome')
        return data
    } catch (error) {
        console.log(error);
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.setItem('email',email);
                nav("/otp")
                return toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }); 
            }
            toast.error(`${error.response.data.detail }`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
}

export async function forgetPassword(email,nav){
    try {
        const body = new FormData()
        body.append("email",email)
        const { data } = await axios.post(`${URL}/forget-password`,body)
        ;
        toast("Reset email sent", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return data
    } catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(`${error.response.data.detail }`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
}

export async function isEmail(email,nav){
    try {
        const body = new FormData()
        body.append("email",email)
        const { data } = await axios.post(`${URL}/decrypt-email`,body)
        ;
        if(data?.email){
            return data
        }else{
            nav("/404")
            return data
        }
    } catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(`${error.response.data.detail }`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
}

export async function changePasswordForgot(token,password,nav){
    try {
        const body = new FormData()
        body.append("token",token)
        body.append("password",password)
        const { data } = await axios.post(`${URL}/reset-password`,body)
        ;
        toast(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        nav("/login")
        return data
    } catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(`${error.response.data.detail }`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        } else {
            toast.error("There was an error in changing password", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
}

export async function changeName(uid,name,s){
    try {
        const body = {
            uid:uid,
            uname:name
        }
        const { data } = await axios.post(`${URL}/change_name`,body)
        if(data.status === 'ok'){
            toast("User name as been changed", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        s(false)
    } catch (error) {
        console.log(error);
    }
}

export default async function getUserInfo(uid){
    try {
        const body = {
            uid: uid
        }
        const { data } = await axios.post(`${URL}/user_info`,body)
        
        localStorage.setItem("usr",data)
        if(data){
            return data;
        }else{
            return null;
        }
    } catch (error) {
        console.log(error);
    }
}

export async function getSettings(apikey,uid){
    try {
        const headers = {
            'X-User-Id':uid
        }
        const { data } = await axios.post(`${URL}/get_settings`, {apikey:apikey},{headers});
        if(data){
            return data;
        }else{
            return null;
        }
    } catch (error) {
        console.log(error);
    }
}

export async function addkbupdate(body){
    try {
        const { data } = await axios.post(`${URL}/add_kb_upload`,body,{timeout: 100000});
        toast("Documents have been successfully uploaded", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return true
    } catch (error) {
        console.log(error);
        if (error.response) {
            // Check for specific error status code and handle accordingly
            if (error.response.status === 401) {
                toast.error("File already exists", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(`${error.response.data.detail || "There was an error in uploading data"}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        return false;
    }
}
export async function addkbtext(body) {
    try {
        const { data } = await axios.post(`${URL}/add_kb_data`, body);
        toast("Document have been successfully uploaded", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return true;
    } catch (error) {
        console.log(error);
        if (error.response) {
            // Check for specific error status code and handle accordingly
            if (error.response.status === 401) {
                toast.error("File already exists", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(`${error.response.data.detail || "There was an error in uploading data"}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        return false;
    }
}

export async function addkburl(body){
    try {
        const { data } = await axios.post(`${URL}/add_kb_url`,body);
        toast("Documents have been successfully uploaded", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return true
    } catch (error) {
        console.log(error);
        if (error.response) {
            // Check for specific error status code and handle accordingly
            if (error.response.status === 401) {
                toast.error("File already exists", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(`${error.response.data.detail || "There was an error in uploading data"}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        return false;
    }
}

export async function addkbqa(body){
    try {
        const { data } = await axios.post(`${URL}/add_kb_qa`,body);
        toast("Document have been successfully uploaded", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return true
    } catch (error) {
        console.log(error);
        if (error.response) {
            // Check for specific error status code and handle accordingly
            if (error.response.status === 401) {
                toast.error("File already exists", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(`${error.response.data.detail || "There was an error in uploading data"}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        return false;
    }
}

export async function getkb(uid){
    try {
        const body = new FormData();
        body.append("uid",uid)
        const { data } = await axios.post(`${URL}/get_my_kb`,body)
        
        if(data  === "No Data on knowledge hub"){
            return JSON.stringify([])
        }else{
            return JSON.stringify(data)
        }
    } catch (error) {
        console.log(error)   
    }
}

export async function trainKb(name,trainid,uid,eid,created_at){
    try {
        const body = new FormData();
        const org = localStorage.getItem("organization")
        body.append("botname",name)
        body.append("uid",uid)
        if(eid){
            body.append("eid",eid)
        }else{
            body.append("eid",0)
        }
        const tid = trainid.join(",");
        body.append("training_files",tid)
        body.append("created_at",created_at)
        body.append("organization",org)
        // console.log(name,trainid,uid,eid)
        const { data } = await axios.post(`${URL}/created_bot`,body)
        
        toast("Assistant has been created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return {ok:true,
            res:data.data,eid:data.id
        }
    } catch (error) {
        console.log(error)
        toast.error("There was an error in uploading data", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function fetchtrainedBots(uid){
    try {
        if(!uid) return alert("no uid")
        const headers = {
            'X-User-Id':uid
        }
        const { data } = await axios.get(`${URL}/getmybots`,{headers})
        
        return {ok:true,data:data.res}
    } catch (error) {
        console.log(error)
    }
}

export async function fetchbotapi(id){
    const body = new FormData();
    body.append("id",id)
    try {
        const { data } = await axios.post(`${URL}/get_one_bot`,body)
        
        return {ok:data.ok,data:data.data,kb:data.kbs}
    } catch (error) {
        console.log(error)
    }
}

export async function fetchkbnames(uid){
    try {
        const body = new FormData();
        body.append("uid",uid)
        const { data } = await axios.post(`${URL}/get_kb_names`,body)
        
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function getAllChats(uid){
    try {
        const headers = {
            'X-User-Id':uid
        }
        const { data } = await axios.get(`${URL}/apis/v1/chat`,{headers})
        
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function createChat(uid,q,botname,nav){
    try {
        const body = new FormData()
        body.append("userID",uid)
        body.append("q",q)
        body.append("botname",botname)
        const body2 = new FormData()
        const { data } = await axios.post(`${URL}/apis/v1/chat/create`,body);
        window.history.pushState('', '',`/chat?id=${data.chat_dict.chatID}`)
        body2.append("chatID",data.chat_dict.chatID)
        body2.append("userID",uid)
        body2.append("question",q)
        const res = await axios.post(`${URL}/apis/v1/chat/ask/question`,body2)
        return {data:res.data,name:data.chat_name,id:data.chat_dict.chatID}
    } catch (error) {
        console.log(error)
    }    
}

export async function getChatById(uid,cid){
    try {
        const headers = {
            'X-User-Id':uid,
            'X-Chat-Id':cid
        }
        const { data } = await axios.get(`${URL}/apis/v1/chat/id/${cid}`,{headers})
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function chatWithBot(cid,uid,q){
    try {
        const body = new FormData();
        body.append('chatID',cid)
        body.append('userID',uid)
        body.append('question',q)
        const { data } = await axios.post(`${URL}/apis/v1/chat/ask/question`,body)
        
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function deleteChat(uid,cid){
    try {
        const headers = {
            'X-User-Id':uid,
            'X-Chat-Id':cid
        }
        const { data } = await axios.delete(`${URL}/apis/v1/chat/id/${cid}`,{headers})
        
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function updateName(uid,cid,botname){
    try {
        const body = new FormData()
        body.append("botname",botname)
        body.append("userID",uid)
        body.append("chatID",cid)
        const { data } = await axios.patch(`${URL}/apis/v1/chat/updatename`,body)
        
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function getNotify(uid){
    try {
        const headers = {
            'X-User-Id':uid
        }
        const { data } = await axios.get(`${URL}/notify/get`,{headers})
        
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function delNotify(id){
    try{
        const { data } = await axios.delete(`${URL}/notify/delete/${id}`)
        return true
    }catch(err){
        console.log(err)
    }
}

export async function updateProf(body){
    try {
        const { data } = await axios.post(`${URL}/user/edit`,body)
        
        toast("Profile has been updated succesfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return data
    } catch (error) {
        console.log(error)
        toast.error("There was an error in uploading data", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function getPublish(form){
    try {
        const { data } = await axios.post(`${URL}/get_kb_details`,form)
        
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function deleteKb(id){
    try {
        const headers = {
            'X-Kb-Id':id
        }
        const {data} = await axios.post(`${URL}/kb/delete`,id,{headers})
        
        toast.success("Document has been removed succesfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return data
    } catch (error) {
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function getBotsName(id){
    try {
        const form = new FormData()
        form.append("uid",id)
        const { data } = await axios.post(`${URL}/get_bots`,form)
        
        return data
    } catch (error) {
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });  
    }
}

export async function deleteAllNotifications(id){
    try {
        const header = {
            "X-User-Id": id
        }
        const { data } = await axios.delete(`${URL}/notify/all`,{header})
        
        return data
    } catch (error) {
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });  
    }
}

export async function getWordCounts(count,form){
    try {
        const { data } = await axios.post(`${URL}/check_size`, form);
        ;
        return data
    } catch (error) {
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function changeChatName(form){
    try {
        const { data } = await axios.post(`${URL}/chat/changeName`, form);
        ;
        return data
    } catch (error) {
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function addNewTeam(form){
    try {
        const { data } = await axios.post(`${URL}/team/create`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function getMyTeams(form){
    try {
        const { data } = await axios.post(`${URL}/team/getall`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function getTeamMembers(form){
    try {
        const { data } = await axios.post(`${URL}/team/getmembers`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function deleteTeam(form){
    try {
        const { data } = await axios.post(`${URL}/team/delete`, form);
        ;
        return toast.success("Team has been deleted succesfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function updateTeam(form){
    try {
        const { data } = await axios.post(`${URL}/team/update`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function deleteMember(form){
    try {
        const { data } = await axios.post(`${URL}/team/delete/member`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function getRoles(){
    try {
        const { data } = await axios.get(`${URL}/teams/get_roles`);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function getMemberRole(form){
    try {
        const { data } = await axios.post(`${URL}/team/member/get_role`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        if(error?.response?.status === 404){
            return "no-org"
        }
    }
}

export async function getKb(form){
    try {
        const { data } = await axios.post(`${URL}/get/kb`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function getKbByName(form){
    try {
        const { data } = await axios.post(`${URL}/get/kb/names`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function queryAnswer(form){
    try {
        const { data } = await axios.post(`${URL}/getanswer`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function updateAnswer(form){
    try {
        const { data } = await axios.post(`${URL}/updateanswer`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function addFeedback(form){
    try {
        const { data } = await axios.post(`${URL}/add_review`, form);
        ;
        return toast.success(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function getMetrics(form){
    try {
        const { data } = await axios.post(`${URL}/metrics`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function chatStreaming(form, setChat) {
    try {
        const response = await fetch(`${URL}/streaming/text`,
            {
                method: "POST",
                body: JSON.stringify(form),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }
        );

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const reader = response.body.getReader();
        let receivedText = '';
        let streamText = '';

        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }
            receivedText += new TextDecoder().decode(value);
            const splitText = receivedText.split("references;;");
            const answer = splitText[0];
            const reference = splitText[1] ? splitText[1].split(";;;") : [];
            streamText += answer.replace(/\\n/gi, "\n").replace(/\n/gi, "<br/>"); 
            setChat(prevChat => {
                const lastItemIndex = prevChat.length - 1;
                const updatedChat = prevChat.map((item, index) => {
                    if (index === lastItemIndex) {
                        return {
                            ...item,
                            answer: streamText,
                            reference: reference,
                            isLoading: false
                        };
                    } else {
                        return item;
                    }
                });
                return updatedChat;
            });
            receivedText = '';
        }
    } catch (error) {
        console.error(error);
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function getAllUrls(form){
    try {
        const { data } = await axios.post(`${URL}/add_main_url`, form);
        ;
        return data
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function saveAllUrls(form){
    try {
        const { data } = await axios.post(`${URL}/add_kb_url`, form);
        ;
        toast.success("Url has been scraped and saved succesfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return true
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function changeKbName(form){
    try {
        const { data } = await axios.post(`${URL}/edit_kb_name`, form);
        ;
        return true
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function editingMember(form,setEditMember){
    try {
        const { data } = await axios.post(`${URL}/team/member/edit`, form);
        ;
        toast.success(data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setEditMember(false)
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function editingTeam(form,setEditMember,handleClear){
    try {
        const { data } = await axios.post(`${URL}/team/edit`, form);
        ;
        toast.success(data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setEditMember(false)
        handleClear()
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export async function contactUS(form){
    try {
        const { data } = await axios.post(`${URL}/contacts`, form);
        ;
        toast.success(data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}


export const changePassword = async(form) => {
    try {
        const { data } = await axios.post(`${URL}/change_password`, form);
        ;
        return toast.success("Password changed successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        console.error(error)
        if (error.response) {
            // Check for specific error status code and handle accordingly
            if (error.response.status === 401) {
                toast.error("Old password doen't Match", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }else if (error.response.status === 403) {
                toast.error("Please enter an new password that doesn't match the old password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }else {
                toast.error(`${error.response.data.detail || "Something went wrong"}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            toast.error("Something went worng", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
}

export async function decryptToken(body){
    try {
        const { data } = await axios.post(`${URL}/decrypt_token`,body);
        return data
    } catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(`${error.response.data.detail || "There was an error in uploading data"}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        return false;
    }
}

export async function acceptInvite(body){
    try {
        const { data } = await axios.post(`${URL}/team/accept_invite`,body);
        return data
    } catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(`${error.response.data.detail || "There was an error in uploading data"}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        return false;
    }
}

export async function getTeamAccess(body){
    try {
        const { data } = await axios.post(`${URL}/team/access`,body);
        return data.access
    } catch (error) {
        console.log(error);
        if (error.response) {
            if (error.response.status === 404){
                return false;
            }
            toast.error(`${error.response.data.detail || "There was an error in uploading data"}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        return false;
    }
}

export async function getAudits(org, uid){
    try {
        const { data } = await axios.get(`${URL}/notify/audit`, {
            params: {
              uid: uid,
              orgn: org
            }
        });
        return data
    } catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(`${error.response.data.detail || "There was an error in uploading data"}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error("There was an error in uploading data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
}

export const getAskllyAdmin = async(body) => {
    try {
        const { data } = await axios.post(`${URL}/get/member/asklly`, body)
        return data
    } catch (error) {
        console.error(error)
    }
}

export const addTenant = async(form, clear) => {
    try {
        const { data } = await axios.post(`${URL}/tenants/add`, form)
        clear()
        return toast.success("Tenant has been onboarded succesfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        if (error.response && error.response.status === 401) {
            toast.error("Tenant already exists", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            console.error(error);
        }
    }
}

export const getTenants = async () => {
    try {
        const { data } = await axios.get(`${URL}/tenants`);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getSingleTenants = async (name) => {
    try {
        const { data } = await axios.get(`${URL}/tenants/?name=${name}`);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const deleteTenant = async (tenantName) => {
    try {
        const { data } = await axios.delete(`${URL}/tenants/delete?tenant_name=${tenantName}`);
        return toast.success("Tenant has been removed successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        if (error.response && error.response.status === 404) {
            toast.error("Tenant not found", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            console.error(error);
        }
    }
}


export const sharedChat = async (chatId) => {
    try {
        const { data } = await axios.get(`${URL}/apis/v1/chat/share/${chatId}`);
        return data;
    } catch (error) {
        console.error("Error sharing chat:", error);
        toast.error("Failed to share chat", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}
