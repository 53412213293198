import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkCold } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

const Answering = ({ i }) => {
  const [statusText, setStatusText] = useState('Thinking');

  useEffect(() => {
    let timer;
    if (i?.isLoading) {
      timer = setTimeout(() => {
        setStatusText('Generating');
      }, 6500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [i?.isLoading]);

  const CodeBlock = ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '');
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };

    if (!inline && match) {
      return (
        <div className="relative">
          <SyntaxHighlighter
            style={coldarkCold}
            language={match[1]}
            PreTag="div"
            {...props}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
          <CopyToClipboard text={String(children)} onCopy={handleCopy}>
            <Button 
              variant="contained" 
              size="small" 
              className="absolute top-2 right-2"
              startIcon={copied ? <CheckIcon /> : <ContentCopyIcon />}
            >
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </CopyToClipboard>
        </div>
      );
    }

    return (
      <code className={className} {...props}>
        {children}
      </code>
    );
  };

  // Custom table styles
  const components = {
    code: CodeBlock,
    ul: ({ node, ...props }) => <ul className="list-disc pl-6 my-2" {...props} />,
    ol: ({ node, ...props }) => <ol className="list-decimal pl-6 my-2" {...props} />,
    li: ({ node, ...props }) => <li className="my-1" {...props} />,
    table: ({ node, ...props }) => (
      <table className="w-full border-collapse border border-gray-300" {...props} />
    ),
    thead: ({ node, ...props }) => (
      <thead className="bg-gray-200" {...props} />
    ),
    tr: ({ node, ...props }) => (
      <tr className="border-b border-gray-300" {...props} />
    ),
    th: ({ node, ...props }) => (
      <th className="px-4 py-2 text-left border border-gray-300 bg-gray-100" {...props} />
    ),
    td: ({ node, ...props }) => (
      <td className="px-4 py-2 border border-gray-300" {...props} />
    )
  };

  return (
    <>
      {i?.isLoading === true ? (
        <div className='flex items-center'>
          <Typography variant='h4' className='mr-3'>{statusText}</Typography>
          <div className="typing-indicator">
            <div className="typing-circle"></div>
            <div className="typing-circle"></div>
            <div className="typing-circle"></div>
            <div className="typing-shadow"></div>
            <div className="typing-shadow"></div>
            <div className="typing-shadow"></div>
          </div>
        </div>
      ) : (
        <Markdown 
          rehypePlugins={[rehypeRaw]} 
          remarkPlugins={[remarkGfm]} 
          components={components}
          className="markdown-content"
        >
          {i?.answer}
        </Markdown>
      )}
    </>
  );
};

export default Answering;