import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import getUserInfo, { addkbqa, getKbByName, getMemberRole } from 'services/app.services';
import { useNavigate } from 'react-router-dom';
import del from 'assets/images/delete.png';
import { Button, TextField, CircularProgress, Typography, Select, MenuItem, IconButton } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { Stack } from '@mui/system';
import { Close } from '@mui/icons-material';

const QnAInputBox = ({close,getKbData,fetchKbNameTop}) => {
  const [qaData, setQAData] = useState([{ question: '', answer: '' }]);
  const [bot,setBot] = useState("")
  const [uname,setUname] = useState("")
  const [fileName,setFileName] = useState("")
  const [loading,setLoading] = useState(false)
  const [uid,setUid] = useState()
  const [kbname,setKbName] = useState([]);
  const [val,setVal] = useState("")
  const nav = useNavigate()
  const [access,setAccess] = useState(false)
  const [kb_id,setKb_Id] = useState("")

  const fetchKbName = async() => {
    const uid = localStorage.getItem("uid");
    setUid(uid)
    const form = new FormData()
    const user = await getUserInfo(uid)
    form.append("email", user?.email)
    const organization = localStorage.getItem("organization")
    form.append("organization",organization)
    const data = await getKbByName(form)
    setKbName(data.kb)
    const form2 = new FormData()
    // const user = JSON.parse(localStorage.getItem("user"))
    form2.append("email",user?.email)
    const role = await getMemberRole(form2)
    if(role?.role?.role === "Admin"){
      setAccess(true)
    }else{
      setAccess(false)
    }
  }
  useEffect(()=>{
    fetchKbName()
  },[])

  const handleAddQA = () => {
    setQAData([...qaData, { question: '', answer: '' }]);
  };

  const handleDelete = (index) => {
    setQAData([...qaData.filter((_,i)=>i !== index)])
  }

  const handleSubmit = async() => {
    setLoading(true) 
    if(qaData[0].question === "" || qaData[0].answer === ""){
      setLoading(false)
      return  toast.error("Enter at least one Q/A to continue", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
    } else if(fileName === ""){
      setLoading(false)
      return  toast.error("Enter the file name", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
    } else if(val === true && bot === ""){
      setLoading(false)
      return  toast.error("Enter the knowledge hub name to continue", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    const uid = localStorage.getItem("uid")
    const body = new FormData();
    const dateObj = new Date
    const day = dateObj.getDate().toString()
    const month = dateObj.getMonth() + 1
    const year = dateObj.getFullYear().toString()
    const user = await getUserInfo(uid)
    body.append("created_id",uid)
    body.append("created_by",user?.uname)
    body.append("filename",fileName)
    if(val !== true){
      body.append("kb_name",val)
    }else if(bot){
      body.append("kb_name",bot)
    }
    body.append("last_modified",`${month.toString()}/${day.toString()}/${year.toString()}`)
    body.append("file_type","qa")
    body.append("kb_id",kb_id)
    const organization = localStorage.getItem("organization")
    body.append("organization",organization)
    body.append("data",JSON.stringify(qaData)) 
    const res = await addkbqa(body);
    if (res){
      setQAData([{ question: '', answer: '' }]);
      setBot("")
      close(false)
      nav('/kb')
    }
    setLoading(false);
    close(false)
    fetchKbNameTop()
    getKbData()
  }

  const handleInputChange = (index, field, value) => {
    const updatedQAData = qaData.map((qa, i) =>
      i === index ? { ...qa, [field]: value } : qa
    );
    setQAData(updatedQAData);
  };

  return (
    <Stack sx={{ mt: -5, overflow: 'hidden'}}>
      <div className="flex justify-between w-full items-center">
        <Typography variant="h3">Capture Q/A to your Knowledge Hub</Typography>
        <IconButton onClick={()=>close(false)}>
            <Close />
        </IconButton>
      </div>
      <Stack justifyContent={'center'} alignItems={'center'}>
        <Stack sx={{width: '100%',mt:2}}>
          <MainCard>  
            {qaData.map((qa, index) => (
              <div key={index} className="mb-2 flex flex-col border rounded p-4">
                  <div className='flex justify-between items-center'>
                    <label className="label">{index+1}. Question</label>
                    <button onClick={()=>handleDelete(index)}><img src={del} className="w-5 cursor-pointer" /></button>
                  </div>
                  <textarea
                      type="text"
                      disabled={loading}
                      placeholder="Enter your question"
                      value={qa.question}
                      onChange={(e) => handleInputChange(index, 'question', e.target.value)}
                      className="textarea h-10"
                  />
                  <label className="label mt-2">Answer</label>
                  <textarea
                      type="text"
                      disabled={loading}
                      placeholder="Enter your answer"
                      value={qa.answer}
                      onChange={(e) => handleInputChange(index, 'answer', e.target.value)}
                      className="textarea h-20"
                  />
              </div>
              ))}
              <div className="flex mt-2 justify-center">
                <Button 
                  onClick={handleAddQA} 
                  variant='outlined'
                  color='secondary'
                  sx={{mt:2}}
                  >
                    Add Another Q/A
                </Button>
              </div>
              <div className="border-2 mt-2 p-2 rounded-xl">
              <div className="flex flex-col mt-2 justify-center">
                <label className="label">File Name</label>
                <TextField
                  variant="outlined"
                  label="Enter the file name"
                  color="secondary"
                  onChange={(e)=>setFileName(e.target.value)}
                  fullWidth
                  disabled={loading}
                  sx={{mt:2,mb:2}}
                  />
                <label className="label">Knowledge Hub</label>
                <Select
                  onChange={(e)=>{
                    setVal(e.target.value)
                    if(e.target.value !== true){
                      const selectedKb = kbname.find(kb => kb.kb_name === e.target.value);
                      setKb_Id(selectedKb?.kb_id)
                    }
                  }}
                  value={val}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  disabled={loading}
                >
                    {kbname?.map((i,index)=>(<MenuItem value={i?.kb_name} key={index}>{i?.kb_name}</MenuItem>))}
                    {access&&<MenuItem value={true}>New Knowledge Hub</MenuItem>}
                </Select>
                {val === true &&<TextField
                  variant="outlined"
                  label="Enter the Knowledge hub name"
                  color="secondary"
                  onChange={(e)=>{
                    setBot(e.target.value)
                    setKb_Id(e.target.value)
                  }}
                  fullWidth
                  disabled={loading}
                  sx={{mt:2}}
                />}
                </div>
                <div className="mt-2">Included Question count: <span className="text-gray-600 font-semibold">{qaData.length}</span></div>
                <div className="flex mt-0 justify-center">
                  {!loading?<Button 
                    onClick={handleSubmit} 
                    variant='outlined'
                    color='secondary'
                    sx={{mt:2}}
                  >
                    Add to Knowledge Hub
                  </Button>:
                  <Button
                    disabled 
                    variant='outlined'
                    color='secondary'
                    sx={{mt:2}}
                  >
                    <CircularProgress color='secondary' />
                  </Button>}
                </div>
              </div>
          </MainCard>
          </Stack>
        </Stack>
    </Stack>
  );
};

export default QnAInputBox;
