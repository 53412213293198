import { ChatBubbleOutline } from '@mui/icons-material';

const icons = {
    ChatBubbleOutline
}

const chat_page = {
    id: 'create_chat',
    title: 'Explore Knowledge',
    // caption: 'Chat with knowledge Base',
    type: 'group',
    children: [
        {
            id: 'chat',
            title: 'Knowledge Assistant',
            type: 'item',
            url: '/chat-with-bot',
            icon: icons.ChatBubbleOutline,
            external: false,
            target: false
        },
    ]
}

export default chat_page