import { Drawer, Menu, MenuItem } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { 
    IconBookUpload , 
    IconFileUpload, 
    IconUpload, 
    IconWorldUpload, 
} from '@tabler/icons';
import Fab from '@mui/material/Fab';
import { Stack } from '@mui/material';
import UploadComp from 'views/Create/Upload';
import TextArea from 'views/Create/Text';
import LinkComp from 'views/Create/Link';
import QnAInputBox from 'views/Create/QA';
import { purple } from '@mui/material/colors';

function NavComp({getKbData,fetchKbName}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [type,setType] = useState("")
    const [drawerOpen,setDrawerOpen] = useState(false)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const nav = useNavigate()

    return (
        <>
            <Fab variant="extended" size="medium" color="secondary"
                sx={{ color: purple[800] }} 
                onClick={handleClick}>
                <AddIcon/>
                Add
            </Fab>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={()=>{
                    setType("upload")
                    setAnchorEl(null)
                    setDrawerOpen(true)
                }}>
                    <Stack direction={"row"} >
                        <IconBookUpload height={20}/>
                        Upload Files
                    </Stack>
                </MenuItem>
                <MenuItem onClick={()=>{
                    setType("data")
                    setAnchorEl(null)
                    setDrawerOpen(true)
                }}>
                    <Stack direction={"row"}>
                        <IconFileUpload  height={20}/>
                        Capture Data
                    </Stack>
                </MenuItem>
                <MenuItem onClick={()=>{
                    setType("link")
                    setAnchorEl(null)
                    setDrawerOpen(true)
                }}>
                    <Stack direction={"row"}>
                        <IconWorldUpload  height={20}/>
                        Crawl Links
                    </Stack>
                </MenuItem>
                <MenuItem onClick={()=>{
                    setType("qna")
                    setAnchorEl(null)
                    setDrawerOpen(true)
                }}>
                    <Stack direction={"row"}>
                        <IconUpload  height={20}/>
                        Question & Answers
                    </Stack>
                </MenuItem>
            </Menu>
            <Drawer
                anchor='right'
                open={drawerOpen}
                onClose={()=>setDrawerOpen(false)}
            >
                <div className='md:w-[50vw] w-[80vw] bg-white'>
                    <div className='mt-10 p-7'>
                        {type === "upload" ? <UploadComp fetchKbNameTop={fetchKbName} getKbData={getKbData} close={setDrawerOpen} />:type === "data" ? <TextArea fetchKbNameTop={fetchKbName} getKbData={getKbData} close={setDrawerOpen}/>:type === "link" ? <LinkComp fetchKbNameTop={fetchKbName} getKbData={getKbData} close={setDrawerOpen}/>:type === "qna" && <QnAInputBox fetchKbNameTop={fetchKbName} getKbData={getKbData} close={setDrawerOpen}/>}
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default NavComp