import React, { useEffect, useState } from "react";
import { AppBar, Box, Button, ButtonBase, Divider, Drawer, Hidden, IconButton, Stack, Typography, styled, CircularProgress } from "@mui/material";
import List from '@mui/material/List';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
import ArticleIcon from '@mui/icons-material/Article';
import AnimateButton from 'ui-component/extended/AnimateButton';

function Nav() {
    const [to,setTo] = useState('')
    useEffect(()=>{
        const id = localStorage.getItem('uid')
        console.log(id)
        if(!id){
            setTo('/login')
        }else{
            setTo('/kb')
        }
    },[])
    const [menuOpen, setMenuOpen] = useState(false);

    const nav = useNavigate()

    const ButtonStyled = styled('button')(({theme})=>({
        backgroundColor: '#fff'
    }))

    const handleNavigate = () => {
        nav(to)
    }

    const handleMenuToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
    };

    const toggleClose = () => {
        setMenuOpen(false)
    }

    const [loading,setLoading] = useState(false)

    const ListComp = (anchor) => (
        <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        onClick={toggleClose}
        onKeyDown={toggleClose}
        >
            <List>
                <ListItem key={'Features'} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Features'} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Documentation'} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <ArticleIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Documentation'} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'faq'} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <PlayArrowIcon />
                        </ListItemIcon>
                        <ListItemText primary={'FAQ'} />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem key={'mybots'} disablePadding>
                    {/* <Stack sx={{mx:3,mt:2}}>
                        <Button color='secondary' variant='outlined' onClick={handleNavigate}>Get Started</Button>
                    </Stack> */}
                    <Box sx={{mx:3,mt:2}}>
                        <AnimateButton>
                            <button onClick={handleNavigate} disabled={loading} className="btn">{loading? <CircularProgress /> :"Get Started"}</button>
                        </AnimateButton>
                    </Box>
                </ListItem>
            </List>
        </Box>
    )

    return (
        <AppBar color="" sx={{ boxShadow: "yes",paddingX:{xs: 4, sm: 10}, paddingY:{xs: 2, sm: 3}}}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <span className="font-[Caveat] text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-600">asklly.ai&nbsp;</span> 
                <Hidden smDown>
                    {/* Display these options on screens wider than or equal to sm breakpoint */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ width: 500 }}
                    >
                        <Typography variant="h4" sx={{ fontWeight: 500 }}>
                            Home
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: 500 }}>
                            Features
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: 500 }}>
                            FAQ
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: 500 }}>
                            Documentation
                        </Typography>
                        <Box> 
                            <Button color='secondary' variant='outlined' onClick={handleNavigate}>Get Started</Button>
                        </Box>
                    </Stack>
                </Hidden>
                <Hidden mdUp>
                    {/* Display this option on screens narrower than md breakpoint */}
                    <IconButton variant="outlined" onClick={handleMenuToggle}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Stack>
            <Drawer
                anchor="top"
                open={menuOpen}
                onClose={toggleClose}
            >
                {ListComp('top')}
            </Drawer>
        </AppBar>
    );
}

export default Nav;