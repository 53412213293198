import { Close, ContentCopy, Edit, Repeat, Send, Share, ThumbDown } from '@mui/icons-material'
import { AppBar, Box, Button, Chip, CircularProgress, IconButton, InputAdornment, InputLabel, List, Modal, TextField, Toolbar, Tooltip, Typography, useTheme, useMediaQuery } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import { useRef } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import getUserInfo, { addFeedback, deleteChat, fetchtrainedBots, getAllChats, getChatById, getKbByName } from 'services/app.services'
import MainCard from 'ui-component/cards/MainCard'
import copy from 'copy-to-clipboard'
import ChatCard from 'components/chatCard/ChatCard';
import jwt_decode from 'jwt-decode';
import SubjectIcon from '@mui/icons-material/Subject';
import ListIcon from '@mui/icons-material/List';
import PDFReader from 'components/pdf/PDFViewer';
import { purple } from '@mui/material/colors';
import useWebSocket from 'react-use-websocket';
import Answering from './AnswerRendering';
import { height, Stack } from '@mui/system';
import ChatHeader from 'layout/MainLayout/Header/ChatHeader'
import SearchSection from 'layout/MainLayout/Header/SearchSection/index';


function stringToColor(name) {
  if (!name || name.length === 0) {
    return '#000000';
  }

  let hash = 0;
  let i;

  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}


function ChatWithBot() {
  const theme = useTheme()
  const nav = useNavigate();
  const inputRef = useRef();
  const [sp,setSp] = useSearchParams();
  const [chatId,setChatId] = useState("")
  const [show,setShow] = useState(true);
  const endOfMessagesRef = useRef(null);
  const [allChat,setAllChat] = useState([]);
  const [cloading,setCloading] = useState(false) 
  const [nloading,setNloading] = useState(false)
  const [disabled,setDisabled] = useState(false)
  const [user,setUser] = useState();
  const [prof,setProf] = useState();
  const [openFeedback,setOpenFeedBack] = useState(false)
  const [feedback,setFeedback] = useState("")
  const [edit,setEdit] = useState(false);
  const [editQuestion,setEditQuestion] = useState("");
  const [optionalFeedback,setOptionalFeedback] = useState("")
  const [chatIndex,setChatIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredChats, setFilteredChats] = useState({
    today: [],
    yesterday: [],
    previous7Days: [],
    previous30Days: [],
    lastYear: []
  });
  const { sendMessage, lastMessage, readyState } = useWebSocket();

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl)

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [kbs,setKbs] = useState([])
  
  const [qna,setQna] = useState([])
  const [botNames,setBotNames] = useState([])
  const [q,setQ] = useState("")
  const [bots, setBots] = useState([])
  const [bot,setBot] = useState([]);
  const [api,setApi] = useState("")
  const [isOpen,setIsOpen] = useState(false)
  const [btnText,setBtnText] = useState("Copy To Clipboard")
  const [links,setLinks] = useState([])
  const [openD,setOpen] = useState(false)
  const [file,setFile] = useState("")
  const [fileName,setFileName] = useState([])
  const [sTodayChat,setSTodayChat] = useState("")
  const [sYesterdayChat,setSYesterdayChat] = useState("")
  const [sPrevious7DaysChat,setSPrevious7DaysChat] = useState("")
  const [sPrevious30DaysChat,setSPrevious30DaysChat] = useState("")
  const [sLastYearChat,setSLastYearChat] = useState("")

  const script = `<script src="https://cdn.jsdelivr.net/npm/chat_x_interface/dist/index.min.js"></script>`

  const copyy = () => {
    const copyText1 = `
      <head>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/chat_x_interface/dist/output.css">
      </head>
      <script>
        window.chatx = {
          chatbotKey: "${api}"
        }
      </script>
      <script src="https://cdn.jsdelivr.net/npm/chat_x_interface/dist/index.min.js"></script>
    `;
    copy(copyText1);
    setBtnText("Copied");
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false)
  };

  const closeModal = () =>{
    setIsOpen(false)
  }

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if(value.length <= 3){
      setBot(value);
    }
  };
  useEffect(() => {
    const query = sp.get('id')
    setQna([])
    setFile("")
    setFileName("")
    setOpen(false)
    setLinks([])
    setChatId(query)
    fetchKbName()
    const uid = localStorage.getItem('uid')
    setNloading(true)
    getUser(uid)
  
    fetchtrainedBots(uid).then((res)=>{
      if(res){
        setBots(res.data[0])
        const newBotNames = res.data[0]?.map((i) => i.title);
        setBotNames(newBotNames);
      }else{
        setBots([])
      }
      setNloading(false)
    })
    getAllChats(uid).then((res)=>{
      setAllChat(res)
    })
    if(query){
      setCloading(true) 
      setQna([])
      getChatById(uid,query).then((res)=>{
        if(Object.keys(res).length === 0 && res.constructor === Object){
          setCloading(false)
          return nav("/chat-with-bot")
        }
        setBot(res?.kbname?.split(","))
        res.chatMessages?.map((i)=>{
          const referencesMatch = i?.answer?.match(/references\s*([\s\S]+)$/i);
          const referencesArray = [];
          if (referencesMatch && referencesMatch[1]) {
            const referencesString = referencesMatch[1].trim();
            referencesArray.push(...referencesString.split(',').map(reference => reference.trim()));
          }       
          
          const parseToMarkDown = i?.answer?.replace(/\\n/gi, "\n");
          setQna((prev)=>[...prev,{...i, answer: parseToMarkDown?.split("references;;")[1] ? parseToMarkDown?.split("references;;")[0]:parseToMarkDown?.split("references")[1]?parseToMarkDown?.split("references")[0]: parseToMarkDown, reference: i?.answer?.split("references;;")[1] ? i?.answer?.split("references;;")[1].split(';;;'):parseToMarkDown?.split("references")[1].split(';')?parseToMarkDown?.split("references")[1].split(';').map(ref => ref.replace(/<br\/>/gi, " ")):[],isLoading: false,links:i.link ? i.link : []}])
          if(i.link){
            setLinks(i.link)
          }else{
            setLinks([])
          }
        })
        setCloading(false)
      })
    }
  },[sp])

  const fetchKbName = async() => {
    const uid = localStorage.getItem("uid")
    const usr = await getUserInfo(uid)
    const form = new FormData()
    form.append("email",usr?.email)
    const organization = localStorage.getItem("organization")
    form.append("organization",organization)
    const data = await getKbByName(form)
    setKbs(data.kb)
  }

  useEffect(()=>{
    if(bots?.length > 0){
      bots?.map((i)=>{
        if(i.title === bot){
          setApi(i.apikey)
        }
      })
    }
  },[bot])

  const toogleData = async() =>{
    setShow(!show)
  }

  const navi = () =>{
    setQna([])
    setChatId("")
    nav('/chat-with-bot')
  }

  const getUser = async() => {
    const id = localStorage.getItem('uid')
    const gauth = localStorage.getItem("gauthcred")
    const usr = await getUserInfo(id)
    if(usr){
      localStorage.setItem("usr",usr)
      setUser(usr)
      if(usr?.prof !== ""){
        setProf(usr?.prof)
        localStorage.setItem("image",usr?.prof)
      }else if(gauth){
        const user = jwt_decode(gauth)
        localStorage.setItem("image",user?.picture)
        setProf(user?.picture)
      }else{
        setProf("")
      }
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  const openPDF = async(index, reference, link) => {
    if (!links || links.length === 0) {
      return;
    } else {
      // Extract the file name from the reference
      const fileName = reference.trim();

      // Find the correct link by matching the file name
      const correctLink = links.find(url => url.includes(fileName));

      if (correctLink) {
        setFile(correctLink);
        setFileName(fileName);
        setOpen(true);
        console.log("Opening PDF:", correctLink); // For debugging
      } else {
        console.error("Link not found for file:", fileName);
        // Optionally, show an error message to the user
      }
    }
  }

  const closePDF = async() => {
    setFile("")
    setFileName("")
    setOpen(false)
  }

  const openFile = () => {
    if(bot === "Select a Bot"){
      toast.error("Select an bot to show api", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    }else{
      setIsOpen(true)
    }
  }

  const convertLocalTime = (dateString) => {
    let date = new Date(dateString * 1000); 
    return formatDate(date);
  };  

  const formatDate = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month as it starts from 0
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  // Get today's date
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Get yesterday's date
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  // Get the date 7 days ago
  const previous7Days = new Date(today);
  previous7Days.setDate(previous7Days.getDate() - 7);
  previous7Days.setHours(0, 0, 0, 0);

  // Get the date 30 days ago
  const previous30Days = new Date(today);
  previous30Days.setDate(previous30Days.getDate() - 30);
  previous30Days.setHours(0, 0, 0, 0);

  // Get the date 1 year ago
  const lastYear = new Date(today);
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  lastYear.setHours(0, 0, 0, 0);

  const splitChatsByTimeframe = () => {
    const todayChats = [];
    const yesterdayChats = [];
    const previous7DaysChats = [];
    const previous30DaysChats = [];
    const lastYearChats = [];

    allChat.forEach(chat => {
      const chatDate = new Date(chat.timestamp * 1000);
      const diffTime = today - chatDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays === 0) {
        todayChats.push(chat);
      } else if (diffDays === 1) {
        yesterdayChats.push(chat);
      } else if (diffDays <= 7) {
        previous7DaysChats.push(chat);
      } else if (diffDays <= 30) {
        previous30DaysChats.push(chat);
      } else {
        lastYearChats.push(chat);
      }
    });

    return { todayChats, yesterdayChats, previous7DaysChats, previous30DaysChats, lastYearChats };
  };

  
  const SortChats = async() => {
    const { todayChats, yesterdayChats, previous7DaysChats, previous30DaysChats, lastYearChats } = splitChatsByTimeframe();
    setSTodayChat([]);
    setSYesterdayChat([]);
    setSPrevious7DaysChat([]);
    setSPrevious30DaysChat([]);
    setSLastYearChat([]);

    setSTodayChat([...todayChats]);
    setSYesterdayChat([...yesterdayChats]);
    setSPrevious7DaysChat([...previous7DaysChats]);
    setSPrevious30DaysChat([...previous30DaysChats]);
    setSLastYearChat([...lastYearChats]);
  }

  useEffect(()=>{
    SortChats()
  },[])

  useEffect(()=>{
    scrollToBottom()
  },[qna])

  const handleSubmitQuestion = useCallback(async () => {
    try {
      const query = sp.get('id')
      const urlParam =  new URLSearchParams(window.location.search).get("id");
      const uid = localStorage.getItem("uid")
      if(bot === "Select a Bot"){
        return toast.error("Select your knowledge hub to chat", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
      setQna(prevQna => [...prevQna, { question: q, answer: 'Generating the answer...', reference:[],isLoading: true,links: []}]);
      setQ("");
      setDisabled(true);
      scrollToBottom();
      const orgn = localStorage.getItem("organization")
      const body = {
        question: q,
        orgn: orgn,
        kbs: bot.join(","),
        cid:  urlParam || "null",
        uid: uid
      }
      const socket = new WebSocket('wss://stream.asklly.ai/v2/ws/chat')
      // const socket = new WebSocket('ws://localhost:51730/v2/ws/chat')
      socket.addEventListener("open", (event) => {
        socket.send(JSON.stringify(body))
      });
      let receivedText = ""
      let streamText = ""
      let baseUrl = window.location.href.split('?')[0];
      socket.addEventListener("message", (event) => {
        receivedText += event.data.toString('utf8')
        const splitText = receivedText?.split("references;;");
        streamText = splitText[0].replace(/\\n/gi, "\n");
        const reference = receivedText?.split("references;;")[1]?.split("::id::")[0] ? receivedText?.split("references;;")[1]?.split("::id::")[0].split(";;;") : [];
        const cid = receivedText?.split("::id::")[1]?.split("##links##")[0];
        const link = receivedText?.split("##links##")[1];
        const parsed = receivedText?.split("##links##")[1]?.split("$;$")
        if(link){
          setLinks([...links,...parsed])
        }
        const { history } = window;
        if(cid){
          var newUrl = baseUrl + '?id=' + cid;
          history.pushState(null, '', newUrl);
        }
        setQna(prevChat => {
          const lastItemIndex = prevChat.length - 1;
          const updatedChat = prevChat.map((item, index) => {
          if (index === lastItemIndex) {
            return {
              ...item,
              answer: streamText,
              reference: reference,
              isLoading: false,
              links: parsed ? parsed : []
            };
          } else {
            return item;
          }
        });
        return updatedChat;
      });
    });
    receivedText = '';
    socket.addEventListener("close",()=>{
      getAllChats(uid).then((res)=>{
        setAllChat(res)
        SortChats()
      })
    })
      setDisabled(false)
    } catch (error) {
      console.error(error)
    }
  }, [q, bot, sp, links]);

  const handleDelete = async(id) => {
    const uid = localStorage.getItem("uid")
    const del = await deleteChat(uid,id)
    sp.delete("id")
    nav('/chat-with-bot')
  }

  const shareChat = () => {
    const { origin } = window.location;
    const url = `${origin}/share?id=${chatId}`
    copy(url)
    toast.success("Coppied Url",{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }

  const copyText = (i) => {
    const answerWithoutBr = qna[i].answer.replace(/<br\/?>/g, '');
    copy(answerWithoutBr)
    toast.success("Coppied text",{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }

  const sendQuestionAndUpdateAnswer = useCallback(async (questionIndex) => {
    try {
      const uid = localStorage.getItem("uid");
      const query = sp.get('id');
      const urlParam =  new URLSearchParams(window.location.search).get("id")
      const body = {
        question: qna[questionIndex].question,
        orgn: localStorage.getItem("organization"),
        kbs: bot.join(","),
        model: "llama2",
        cid: urlParam || "null",
        uid: uid
      };
      setQna(prevQna => {
        const updatedQna = [...prevQna];
        updatedQna[questionIndex] = { ...updatedQna[questionIndex], isLoading: true, answer: ""};
        return updatedQna;
      });
      setDisabled(true);
      const socket = new WebSocket('wss://stream.asklly.ai/v2/ws/rechat')
      socket.addEventListener("open", (event) => {
        socket.send(JSON.stringify(body))
      });
      let receivedText = ""
      let streamText = ""
      let baseUrl = window.location.href.split('?')[0];
      socket.addEventListener("message", (event) => {
        receivedText += event.data.toString('utf8')
        const splitText = receivedText?.split("references;;");
        streamText = splitText[0].replace(/\\n/gi, "\n");
        const reference = receivedText?.split("references;;")[1]?.split("::id::")[0] ? receivedText?.split("references;;")[1]?.split("::id::")[0].split(";;;") : [];
        const cid = receivedText?.split("::id::")[1]?.split("##links##")[0];
        const link = receivedText?.split("##links##")[1];
        const parsed = receivedText?.split("##links##")[1]?.split("$;$")
        if(link){
          setLinks([...links,...parsed])
        }
        setQna(prevChat => {
          const lastItemIndex = prevChat.length - 1;
          const updatedChat = prevChat.map((item, index) => {
          if (index === lastItemIndex) {
            return {
              ...item,
              answer: streamText,
              reference: reference,
              isLoading: false,
              links: parsed ? parsed : []
            };
          } else {
            return item;
          }
        });
        return updatedChat;
      });
    });
      receivedText = '';
      socket.addEventListener("close",()=>{
        getAllChats(uid).then((res)=>{
          setAllChat(res)
          SortChats()
        })
      })
      return { success: true, message: "Answer refreshed successfully" };
    } catch (error) {
      console.error(error);
      setDisabled(false);
      return { success: false, message: "Failed to refresh answer" };
    }
  }, [qna, bot, sp, links]);

  const sendUpdatedQuestion = useCallback(async (questionIndex) => {
    setEdit(false)
    try {
      const uid = localStorage.getItem("uid");
      const urlParam =  new URLSearchParams(window.location.search).get("id")
      const query = sp.get('id');
      const body = {
        question: editQuestion,
        orgn: localStorage.getItem("organization"),
        kbs: bot.join(","),
        model: "llama2",
        cid: urlParam || "null",
        uid: uid
      };
      setQna(prevQna => {
        const updatedQna = [...prevQna];
        updatedQna[questionIndex] = { ...updatedQna[questionIndex],question: editQuestion, isLoading: true, answer: ""};
        return updatedQna;
      });
      setDisabled(true);
      const socket = new WebSocket('wss://stream.asklly.ai/v2/ws/rechat')
      socket.addEventListener("open", (event) => {
        socket.send(JSON.stringify(body))
      });
      let receivedText = ""
      let streamText = ""
      let baseUrl = window.location.href.split('?')[0];
      socket.addEventListener("message", (event) => {
        receivedText += event.data.toString('utf8')
        const splitText = receivedText?.split("references;;");
        streamText = splitText[0].replace(/\\n/gi, "\n");
        const reference = receivedText?.split("references;;")[1]?.split("::id::")[0] ? receivedText?.split("references;;")[1]?.split("::id::")[0].split(";;;") : [];
        const cid = receivedText?.split("::id::")[1]?.split("##links##")[0];
        const link = receivedText?.split("##links##")[1];
        const parsed = receivedText?.split("##links##")[1]?.split("$;$")
        if(link){
          setLinks([...links,...parsed])
        }
        setQna(prevChat => {
          const lastItemIndex = prevChat.length - 1;
          const updatedChat = prevChat.map((item, index) => {
          if (index === lastItemIndex) {
            return {
              ...item,
              answer: streamText,
              reference: reference,
              isLoading: false,
              links: parsed ? parsed : []
            };
          } else {
            return item;
          }
        });
        return updatedChat;
      });
    });
      receivedText = '';
      socket.addEventListener("close",()=>{
        getAllChats(uid).then((res)=>{
          setAllChat(res)
          SortChats()
        })
      })
      setDisabled(false);
      return { success: true, message: "Answer refreshed successfully" };
    } catch (error) {
      setDisabled(false);
      return { success: false, message: "Failed to refresh answer" };
    }
  }, [editQuestion, bot, sp, links]);
  
  const HandleEditQuestion = () =>{
    setEditQuestion(qna[qna.length - 1].question);
    setEdit(true)
    inputRef.current.focus()
  }

  const handleCancel = () => {
    setEdit(false)
    setEditQuestion(" ");
  }

  const handleFeedback = async() => {
    const uid = localStorage.getItem("uid")
    if(!feedback){
      return toast.error("Select an feedback", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (feedback === "Others" && optionalFeedback === ""){
      return toast.error("Enter an feedback", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    const body = {
      useruid: uid,
      review: "negative",
      feedback: feedback + " " +optionalFeedback,
      chatid: chatId,
      chatindex: chatIndex
    }
    await addFeedback(body)
    setOpenFeedBack(false)
    setFeedback("")
    setOptionalFeedback("")
  }
  
  const { todayChats, yesterdayChats, previous7DaysChats, previous30DaysChats, lastYearChats } = splitChatsByTimeframe();
  const sortedTodayChats = [...todayChats]
  const sortedYesterdayChats = [...yesterdayChats]
  const sortedPrevious7DaysChats = [...previous7DaysChats]
  const sortedPrevious30DaysChats = [...previous30DaysChats]
  const sortedLastYearChats = [...lastYearChats]

  const sortChats = (chats) => {
    return chats.sort((a, b) => b.timestamp - a.timestamp);
  };

  useEffect(() => {
    const filterChats = (chats) => {
      return sortChats(chats.filter(chat => 
        chat.chatName.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    };
  
    setFilteredChats({
      today: filterChats(sortedTodayChats),
      yesterday: filterChats(sortedYesterdayChats),
      previous7Days: filterChats(sortedPrevious7DaysChats),
      previous30Days: filterChats(sortedPrevious30DaysChats),
      lastYear: filterChats(sortedLastYearChats)
    });
  }, [searchTerm, sortedTodayChats, sortedYesterdayChats, sortedPrevious7DaysChats, sortedPrevious30DaysChats, sortedLastYearChats]);

  const renderChatSection = (title, chats) => {
    if (chats.length === 0) return null;
    return (
      <Stack>
        <Typography sx={{fontWeight: 400}}>{title}</Typography>
        {chats.map((i) => (
          <Link key={i.chatID} to={`/chat-with-bot?id=${i.chatID}`}>
            <ChatCard i={i} handleDelete={handleDelete} />
          </Link>
        ))}
      </Stack>
    );
  };

  return (
    <Box sx={{
      backgroundColor: '#fff',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
        }}
      >
        <Toolbar>
          <ChatHeader handleLeftDrawerToggle={null} showHome={true} bot={bot} handleSelectChange={handleSelectChange} kbs={kbs} navi={navi} toogleData={toogleData} chatId={chatId} shareChat={shareChat} />
        </Toolbar>
      </AppBar>
      <Stack direction={'row'} sx={{flex: 1, mt: 9,overflow: "hidden"}}>
        {show && (
        <MainCard sx={{
          width: { xs: '100%', sm: '19%' },
          display:`${show ?{sm:'none',md:"block"}: 'none'}`}}
        >
          {/* <Button onClick={navi} variant='outlined' color='secondary' fullWidth>New Chat +</Button> */}
          <SearchSection search={searchTerm} setSearch={setSearchTerm} />
          <List sx={{overflow:'auto', flex: 1}}>
            {!nloading ? 
              <>
                {renderChatSection("Today", filteredChats.today)}
                {renderChatSection("Yesterday", filteredChats.yesterday)}
                {renderChatSection("Past 7 Days", filteredChats.previous7Days)}
                {renderChatSection("Past 30 Days", filteredChats.previous30Days)}
                {renderChatSection("Last Year", filteredChats.lastYear)} 
              </>:<Stack justifyContent={'center'} alignItems={'center'} sx={{height:'100%'}}>
                <CircularProgress color='secondary' /> 
              </Stack> 
            }
          </List>
        </MainCard>
        )}
        <Stack
          sx={{
            flex: 1, 
            backgroundColor: "#EEF2F6", 
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            marginTop: 2,
            padding: "16px",
            overflow: "hidden"
          }}
        >
        <Stack
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 64px)', // Adjust based on your AppBar height
            overflow: 'hidden',
            backgroundColor: "white",
            borderRadius: 2
          }}
        >
            <List
              sx={{
                flex: 1,
                overflow: 'auto',
                padding: 2,
                  '&::-webkit-scrollbar': {
                  display: 'none',
                },
                scrollbarWidth: 'none',
                '-ms-overflow-style': 'none',
              }}
            >
            {cloading ? 
              <Stack justifyContent={'center'} alignItems={'center'} sx={{height:'100%'}}>
                <CircularProgress color='secondary' /> 
              </Stack>:qna?.length > 0 && qna?.map((i,index)=>(<Box sx={{ overflow:'auto', borderBottom:0 , borderColor:"#c1c1c1" }}>
                <Stack direction={'row'} sx={{height:'fit-content',p:{sm:1,md:2}}} alignItems={'center'}>
                  <Stack direction={"column"} sx={{width: "100%"}}>
                    {edit === true && index === qna.length - 1?
                    <Stack direction={"column"}>
                      <TextField
                        onChange={(e)=>setEditQuestion(e.target.value)}
                        value={editQuestion}
                        multiline
                        fullWidth
                        variant='outlined'
                        color='secondary'
                        ref={inputRef}
                      />
                      <Stack direction={"row"} sx={{mt: 2}}>
                        <Button variant='outlined' color='secondary' onClick={()=>sendUpdatedQuestion(index)}>Submit</Button>
                        <Button sx={{ml: 2}} onClick={handleCancel} variant='text' color='error'>Cancel</Button>
                      </Stack>
                    </Stack>
                    :<Stack direction={"column"}>
                      <Typography sx={{fontWeight:'500'}} variant='h2'>{i.question}</Typography>
                      {index === qna.length - 1 && 
                      <div className='w-20'>
                        <Tooltip title="edit question" placement='bottom'>
                          <IconButton disabled={i.isLoading} onClick={HandleEditQuestion}>
                            <Edit sx={{width:20}} />
                          </IconButton>
                        </Tooltip>
                      </div>}
                    </Stack>}
                  </Stack>
                </Stack>
                <Stack direction={'row'} sx={{height:'fit-content',p:{sm:1,md:2}}} alignItems={'flex-start'}>
                  <Box sx={{ display:{sm:"none",md:"block"} }}>
                    <SubjectIcon />
                  </Box>
                  <Stack direction={"column"}>
                    <Typography variant='h3' sx={{fontWeight:'700',ml:2,mb:2}}>
                      Answer
                    </Typography>
                    <Answering i={i} />
                  </Stack>
                </Stack>
                {i.isLoading === false && <Stack sx={{ml:{sm:1,md:5}}} justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    {index === qna.length - 1 && 
                      <Stack direction={"row"} alignItems={"center"}>
                        <Tooltip title="Repeat Question" placement='bottom'>
                          <IconButton onClick={()=>sendQuestionAndUpdateAnswer(index)}>
                            <Repeat sx={{width:20}} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    }
                    <Stack direction={"row"} alignItems={"center"} >
                      <Tooltip title="copy" placement='bottom'>
                        <IconButton onClick={()=>copyText(index)}>
                          <ContentCopy sx={{ cursor: "pointer",p:1 ,":hover":{backgroundColor:"#f1f1f1",animationDuration:10},borderRadius: 20,fontSize:30}} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    {/* <Stack direction={"row"} alignItems={"center"}>
                      <Tooltip title="Good Review" placement='bottom'>
                        <IconButton>
                          <ThumbUp sx={{ cursor: "pointer",p:1 ,":hover":{backgroundColor:"#f1f1f1",animationDuration:10},borderRadius: 20,fontSize:30}} />
                        </IconButton>
                      </Tooltip>
                    </Stack> */}
                    <Stack direction={"row"} alignItems={"center"}>
                      <Tooltip title="Bad Review" placement='bottom'>
                        <IconButton onClick={()=>{
                          setChatIndex(index)
                          setOpenFeedBack(true)
                        }}>
                          <ThumbDown sx={{ cursor: "pointer",p:1 ,":hover":{backgroundColor:"#f1f1f1",animationDuration:10},borderRadius: 20,fontSize:30}} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Stack>}
                {i?.reference?.length > 0 &&<Stack direction={'row'} sx={{height:'fit-content',p:{sm:1,md:2}}} alignItems={'flex-start'}>
                  <Box sx={{ display:{sm:"none",md:"block"} }}>
                    <ListIcon />
                  </Box>
                  {i.isLoading === false && <Stack direction={"column"}>
                    <Typography variant='h3' sx={{fontWeight:'700',ml:2,mb:2}}>
                      Sources
                    </Typography>
                    <div className='grid md:grid-cols-8 gap-3 grid-cols-2 mt-2'>
                      {i?.reference?.map((name,index) => (
                        <Chip
                          key={index}
                          label={name !== "\n\n" && name}
                          sx={{ color: purple[800]}}
                          variant = "outlined"
                          onClick={() => openPDF(index, name, i.links[index])}
                        />
                      ))}  
                    </div>
                  </Stack>}
                </Stack>}
                <div ref={endOfMessagesRef} />
              </Box>))}
            </List>
            
            <Box sx={{ p: 2 }}>
              <TextField
                fullWidth
                color='secondary'
                label="Ask a question"
                value={q}
                onChange={(e) => setQ(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmitQuestion()
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton disabled={disabled} id="button" onClick={handleSubmitQuestion}>
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Typography sx={{ mt: 2, color: "#b1b1b1", textAlign: "center" }}>
                asklly.ai can make mistakes.
              </Typography>
            </Box>
          </Stack>  
        </Stack>
      </Stack>
      <Modal
        open={openFeedback}
        onClose={()=>setOpenFeedBack(false)}
      >
        <div className="h-full flex justify-center items-center">
          <div className="md:p-10 p-3 rounded-md bg-white md:w-[60%] flex-col justify-center items-center">
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant='h2'>Feedback</Typography>
              <IconButton onClick={()=>setOpenFeedBack(false)}>
                <Close />
              </IconButton>
            </Stack>
            <Typography variant='body1'>Your feedback will help us improve the model</Typography>
            <div className='my-8 grid grid-cols-3 gap-6'>
              <Chip
                label={"Answer Inaccurate"} 
                sx={{ color: purple[800] }} 
                variant={feedback === "Answer Inaccurate" ? "filled" : "outlined"}
                onClick={()=>{
                  setFeedback("Answer Inaccurate")
                }}
              />
              <Chip
                label={"Didnt fully followed the prompt"}
                sx={{ color: purple[800] }} 
                variant={feedback === "Didnt fully followed the prompt" ? "filled" : "outlined"}
                onClick={()=>{
                  setFeedback("Didnt fully followed the prompt")
                }}
              />
              <Chip
                label={"No Answer came"}
                sx={{ color: purple[800] }} 
                variant={feedback === "No Answer came" ? "filled" : "outlined"}
                onClick={()=>{
                  setFeedback("No Answer came")
                }}
              />
              <Chip
                label={"Being lazy"}
                sx={{ color: purple[800] }} 
                variant={feedback === "Being lazy" ? "filled" : "outlined"}
                onClick={()=>{
                  setFeedback("Being lazy")
                  
                }}
              />
              <Chip
                label={"Others"}
                sx={{ color: purple[800] }} 
                variant={feedback === "Others" ? "filled" : "outlined"}
                onClick={()=>{
                  setFeedback("Others")
                }}
              />
            </div>
            <TextField
              fullWidth
              color='secondary'
              placeholder='(optional) Enter your Feedback about the answer'
              onChange={(e)=>setOptionalFeedback(e.target.value)}
            />
            <Button variant='outlined' color='secondary' sx={{mt:2}} onClick={handleFeedback}>Submit</Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="h-full flex justify-center items-center">
          <div className="md:p-10 p-3 rounded-md bg-white md:w-[70%] flex-col justify-center items-center">
            <h1 className="font-bold text-xl">Script Code</h1>
            <div className="mockup-code">
              <pre data-prefix="1"><code>{"<head>"}</code></pre>
              <pre data-prefix="2"><code>{'   <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/chat_x_interface/dist/output.css">'}</code></pre>
              <pre data-prefix="3"><code>{'</head>'}</code></pre>
              <pre data-prefix="4"><code>{'<script>'}</code></pre>
              <pre data-prefix="5"><code>{'   window.chatx = {'}</code></pre>
              <pre data-prefix="6"><code>{`        chatbotKey: "${api}"`}</code></pre>
              <pre data-prefix="7"><code>{'   }'}</code></pre>
              <pre data-prefix="8"><code>{'</script>'}</code></pre>
              <pre data-prefix="9"><code>{script}</code></pre>
            </div>
            <button className="px-6 py-3 mt-2 text-white bg-black hover:scale-110 transition-all ease-in-out duration-300 rounded-md cursor-pointer" onClick={copyy}>{btnText}</button>
            <a className="px-6 py-3 mt-2 text-white bg-red-400 ml-2 hover:scale-110 transition-all ease-in-out duration-300 rounded-md cursor-pointer" href="javascript:void(0);" onClick={closeModal}>Close</a>
          </div>
        </div>
      </Modal>
      <PDFReader file={file} fileName={fileName} openDrawer={openD} close={closePDF} />
    </Box>
  )
}

export default ChatWithBot