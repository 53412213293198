// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { IconTrash } from '@tabler/icons'
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import { delNotify } from 'services/app.services';
import { Link, useNavigate } from 'react-router-dom';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({list,setOpen,handleClose}) => {
  const theme = useTheme();

  const chipSX = {
    height: 24,
    padding: '0 6px'
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
    marginRight: '5px'
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    height: 28
  };

  const del = async(id)=>{
    const res = await delNotify(id)
    if(res){
      setOpen(false)
    }
  }

  const nav = useNavigate()

  const navigate = (link) => {
    nav(link)
    handleClose()
  }

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22
        },
        '& .MuiDivider-root': {
          my: 0
        },
        '& .list-container': {
          pl: 7
        }
      }}
    >
      {list?.length > 0 ? list?.map((item)=>(<Stack>
        <ListItemWrapper>
          <ListItem alignItems="left">
            <ListItemAvatar>
              <Avatar
                sx={{
                  color: theme.palette.primary.dark,
                  backgroundColor: theme.palette.primary.light,
                  border: 'none',
                  borderColor: theme.palette.primary.main
                }}
              >
                <IconMailbox stroke={1.5} size="1.3rem" />
              </Avatar>
            </ListItemAvatar>
            <button onClick={()=>navigate(item?.nav)}>
              <ListItemText className='hover:underline' primary={<Typography variant="subtitle1">{item?.title}</Typography>} />
            </button>
            <ListItemSecondaryAction>
              <Grid container justifyContent="flex-end" className='ml-4'>
                <Grid item>
                  <Typography variant="subtitle2">
                    <IconButton onClick={()=>del(item.id)}>
                      <IconTrash />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </ListItemSecondaryAction>
          </ListItem>
          <Grid container direction="column" className="list-container">
            <Grid item xs={12} sx={{ pb: 2 }}>
              <Typography variant="subtitle2">{item?.message}</Typography>
            </Grid>
          </Grid>
        </ListItemWrapper>
        <Divider />
      </Stack>)):
      <Typography variant='h5' sx={{fontWeight:'600',height:80,width:300,display:'flex',justifyContent:'center',alignItems:'center'}}>No Notifications Yet</Typography>
      }
    </List>
  );
};

export default NotificationList;
