// assets
import { IconKey, Icon, IconAlertOctagon, IconApps, IconBrain, IconPackage} from '@tabler/icons';
import { ChatBubbleOutline } from '@mui/icons-material';


// constant
const icons = {
  IconKey, IconAlertOctagon, IconApps, IconBrain, IconPackage, ChatBubbleOutline
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const bot_pages = {
  id: 'create_bot',
  title: 'Share Knowledge',
  type: 'group',
  children: [
    {
      id: 'bots',
      title: 'Available Assistants',
      type: 'item',
      url: '/mybots',
      icon: icons.IconApps,
      breadcrumbs: true
    },
    {
      id: 'create_bot',
      title: 'Create Assistant',
      type: 'item',
      url: '/train',
      icon: icons.IconBrain,
      breadcrumbs: true
    }
    // {
    //   id: 'test_bot',
    //   title: 'Test AI ChatBot',
    //   type: 'item',
    //   url: '/test',
    //   icon: icons.IconAlertOctagon,
    // },
    // {
    //   id: 'publish_bot',
    //   title: 'Publish AI ChatBot',
    //   type: 'item',
    //   url: '/publish',
    //   icon: icons.IconPackage,
    // },
  ]
};

export default bot_pages;
