import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Link } from "react-router-dom";
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import getUserInfo, { deleteKb, getKb, getMemberRole, getKbByName, changeKbName } from 'services/app.services';
import MainCard from 'ui-component/cards/MainCard';
import {
  Box,
  Button,
  Typography,
  Modal,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { Stack } from '@mui/system';
import { ChevronRight, Home, Delete, Edit } from "@mui/icons-material";
import deleteImg from "assets/images/Inbox cleanup-rafiki.png"
import NavComp from 'components/NavComp/NavComp';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs } from "react-pdf";
import PDFReader from 'components/pdf/PDFViewer';
import Chip from '@mui/material/Chip';
import { purple } from '@mui/material/colors';
import { toast } from 'react-toastify';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

const ManageTrainAI = () => {
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [ok, setOK] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [fileType, setFileType] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [selectedKbNames, setSelectedKbNames] = useState([]);
  const [docs, setDocs] = useState([]);
  const [access, setAccess] = useState();
  const [uid, setUid] = useState();
  const [kbname, setKbName] = useState([]);
  const [editModel, setEditModel] = useState(false);
  const [editModelInp, setEditModelInp] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOldKbName, setSelectedOldKbName] = useState("");
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

  // Define columns based on screen size
  const columns = isMobile
    ? [
        {
          accessorKey: 'kb_name',
          header: 'Knowledge Hub',
          Cell: ({ cell }) => {
            const cell_value = cell.getValue();
            return (
              <span>
                {cell_value.length > 20 ? `${cell_value.substring(0, 20)}...` : cell_value}
              </span>
            );
          },
        },
        {
          accessorKey: 'file_name', // Only show file_name on mobile
          header: 'File Name',
          Cell: ({ cell }) => {
            const fileName = cell.getValue();
            return (
              <span>
                {fileName.length > 25 ? `${fileName.substring(0, 25)}...` : fileName}
              </span>
            );
          },
        },
      ]
    : [
        {
          accessorKey: 'kb_name',
          header: 'Knowledge Hub',
        },
        {
          accessorKey: 'file_name',
          header: 'Documents',
          Cell: ({ cell }) => {
            const fileName = cell.getValue();
            return (
              <span>
                {fileName.length > 25 ? `${fileName.substring(0, 25)}...` : fileName}
              </span>
            );
          },
        },
        {
          accessorKey: 'created_by',
          header: 'Created By',
        },
        {
          accessorKey: 'last_modified',
          header: 'Created On',
        },
        {
          accessorKey: 'file_size',
          header: 'Size (mb)',
        },
        {
          accessorKey: 'status',
          header: 'Status',
        },
      ];

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  function openFileDrawer(index) {
    const row = showData[index];
    if (row?.file_name.toLowerCase().includes(".pdf")) {
      setFileType("pdf");
    } else if (row?.file_name.toLowerCase().includes(".docx") || row?.file_name.toLowerCase().includes(".doc")) {
      setFileType("docx");
    } else if (row?.file_name.toLowerCase().includes(".txt")) {
      setFileType("txt");
      fetch(file).then((res) => res.text().then(d => {
      }));
    } else {
      setFileType("No");
    }
    setFile(row?.location);
    setFileName(row?.file_name);
    setDocs([{ uri: row?.location }]);
    setOpenDrawer(true);
  }

  async function getKbData() {
    const uid = localStorage.getItem("uid");
    const usr = await getUserInfo(uid);
    setUid(uid);
    const form = new FormData();
    const email = usr.email;
    if (email == null) {
      email = localStorage.getItem("email");
    }
    form.append("email", email);
    const organization = localStorage.getItem("organization");
    form.append("organization", organization);
    const resp = await getKb(form);
    const acc = await getMemberRole(form);
    let acces = acc.role.role.split(",")?.some(i => i === "Admin" || i === "Create Knowledge Base");
    console.log(acces)
    setAccess(acces);
    if (resp?.kb.length > 0) {
      setData(resp?.kb);
      setShowData(resp?.kb);
    } else {
      setData([]);
    }
  }

  const fetchKbName = async () => {
    const form = new FormData();
    const uid = localStorage.getItem("uid");
    const user = await getUserInfo(uid);
    form.append("email", user?.email);
    const organization = localStorage.getItem("organization");
    form.append("organization", organization);
    const data = await getKbByName(form);
    setKbName(data.kb);
    const form2 = new FormData();
    form2.append("email", user?.email);
    const role = await getMemberRole(form2);
    const acces = role.role.role.split(",")?.some(i => i === "Admin" || i === "Create Knowledge Base");
    setAccess(acces)
  }

  useEffect(() => {
    fetchKbName();
    getKbData();
  }, []);

  const closeDrawer = () => {
    setFile(null);
    setFileType(null);
    setOpenDrawer(false);
  }

  const handleKBClick = (kbName) => {
    setSelectedKbNames((prevSelected) => {
      const newSelectedKbNames = prevSelected.includes(kbName)
        ? prevSelected.filter((name) => name !== kbName)
        : [...prevSelected, kbName];

      setShowData(
        newSelectedKbNames.length > 0
          ? data.filter((item) => newSelectedKbNames.includes(item.kb_name))
          : data
      );

      return newSelectedKbNames;
    });
  };

  const handleClearFilter = () => {
    setSelectedKbNames([]);
    setShowData(data);
  };

  const handleChangeKbName = async () => {
    setEditModel(true);
  }

  const handleEditModelChange = async () => {
    setLoading(true);
    const organization = localStorage.getItem("organization");
    const formData = new FormData();
    formData.append("oldname", selectedOldKbName);
    formData.append("newname", editModelInp);
    formData.append("organization", organization);
    await changeKbName(formData);
    await fetchKbName();
    await getKbData();
    toast.success("Knowledge Hub name changed successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setSelectedOldKbName("");
    setSelectedKbNames([]);
    setEditModel(false);
    setEditModelInp("");
    setLoading(false);
  }

  const handleDelete = async () => {
    setLoading(true);
    let deleteKeys = [];
    const uid = localStorage.getItem("uid");
    const org = localStorage.getItem("organization");
    Object.keys(rowSelection).map((i) => {
      deleteKeys.push(showData[i].id);
    });
    const form = new FormData();
    form.append("organization",org)        
    form.append("uid", uid);
    form.append("org", org);
    form.append("id", deleteKeys.join());
    await deleteKb(form);
    getKbData();
    setRowSelection({});
    setOK(false);
    setOpen1(false);
    handleClearFilter();
    fetchKbName();
    setLoading(false);
  }

  return (
    <Stack>
      <MainCard>
        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
          <div className="flex justify-center items-center">
            <Link to='/welcome'>
              <Home color="secondary" />
            </Link>
            <ChevronRight />
            <Typography sx={{ fontWeight: 'bold' }}>Knowledge Hub</Typography>
          </div>
          {access && <NavComp fetchKbName={fetchKbName} getKbData={getKbData} />}
        </Stack>
        <div className='grid md:grid-cols-8 gap-3 grid-cols-2 mt-5'>
          {kbname?.map((i) => (
            <Chip
              key={i?.kb_name}
              label={i?.kb_name}
              sx={{ color: purple[800] }}
              variant={selectedKbNames.includes(i?.kb_name) ? "filled" : "outlined"}
              onClick={() => handleKBClick(i?.kb_name)}
            />
          ))}
          {selectedKbNames.length > 0 && (
            <Chip
              label="Clear Filter"
              sx={{ color: 'primary.contrastText', bgcolor: 'error.main', ml: 1 }}
              onClick={handleClearFilter}
            />
          )}
        </div>
      </MainCard>

      <Stack sx={{ mt: 2, borderRadius: '8px', overflow: 'hidden'}}>
        <MaterialReactTable
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              openFileDrawer(row.index);
            }
          })}
          columns={columns}
          data={showData}
          enableRowSelection
          state={{ rowSelection: rowSelection }}
          onRowSelectionChange={setRowSelection}
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
              <Button
                disabled={Object.keys(rowSelection).length === 0}
                onClick={() => setOpen1(true)}
                startIcon={<Delete />}
                variant="outlined"
                color="error">
                Delete
              </Button>
              {selectedKbNames.length > 0 && <Button
                disabled={!selectedKbNames.length > 0}
                onClick={() => handleChangeKbName()}
                startIcon={<Edit />}
                variant="outlined"
                color="secondary">
                Edit Knowledge Hub name
              </Button>}
            </Box>
          )}
          initialState={{
            sorting: [{ id: 'last_modified', desc: true }]
          }}
          sx={{
            borderRadius: '8px', // Adjust the radius as needed
            overflow: 'hidden', // Ensure the corners are rounded
          }}
          // Customizing the pagination options
          muiTablePaginationProps={{
            labelRowsPerPage: '',
          }}
        />
      </Stack>
      <Modal
        open={open1}
        onClose={() => {
          setOK(false);
          setOpen1(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{}}
      >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <div className='bg-white h-[45vh] md:w-[30vw] w-[90%] p-2 rounded-md flex flex-col justify-center items-center'>
            {/* <img src={deleteImg} className='w-40' /> */}
            <div className='flex flex-col justify-center items-start'>
              <Typography variant='h3' sx={{ mx: 1 }}>Delete Items</Typography>
              <div className='flex items-center mt-2'>
                <Checkbox color='secondary' value={ok} onClick={() => { setOK(!ok); }} />
                <p className='font-semibold'>I Understand that deleting items cannot be recovered</p>
              </div>
              <div className='flex items-center mt-2'>
                <Button color='secondary' sx={{ mr: 2 }} onClick={() => {
                  setOK(false);
                  setOpen1(false);
                }}>Cancel</Button>
                <Button variant='outlined' color='error' disabled={ok === false || loading === true}
                  onClick={handleDelete}
                >{loading?<CircularProgress color='error' />:"Delete"}</Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={editModel}
        onClose={() => setEditModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{}}
      >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <div className='bg-white h-[50vh] md:w-[30vw] w-[90%] p-8 rounded-md flex flex-col justify-center items-start'>
            <div className='flex flex-col w-full justify-center items-start'>
              <Typography variant='h3' sx={{ mx: 1 }}>Edit Knowledge Hub Name</Typography>
              <Select
                value={selectedOldKbName}
                onChange={(e) => {
                  setEditModelInp(e.target.value);
                  setSelectedOldKbName(e.target.value);
                }}
                fullWidth
                sx={{ mt: 2 }}
              >
                {selectedKbNames.map((kbName) => (
                  <MenuItem key={kbName} value={kbName}>
                    {kbName}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                variant="outlined"
                className='mt-4 mb-3'
                label="Enter the new Knowledge Hub name"
                id="kbname"
                color="secondary"
                fullWidth
                sx={{ mr: 2 }}
                value={editModelInp}
                onChange={(e) => setEditModelInp(e.target.value)}
              />
              <div className='flex items-center mt-2'>
                <Button color='error' sx={{ mr: 2 }} disabled={loading} onClick={() => {
                  setEditModelInp("");
                  setEditModel(false);
                }}>Cancel</Button>
                <Button variant='outlined' color='secondary' disabled={loading} onClick={handleEditModelChange}>
                  Update Knowledge Hub name
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <PDFReader close={closeDrawer} file={file} openDrawer={openDrawer} fileName={fileName} />
    </Stack>
  );
};

export default ManageTrainAI;