import { Close, Delete, Done, Edit, Message, MoreHoriz } from '@mui/icons-material'
import { CircularProgress, IconButton, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { changeChatName } from 'services/app.services';

function ChatCard({i,handleDelete}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open,setOpen] = useState(false)
    const [value,setValue] = useState(i.chatName)
    const [edit,setEdit] = useState(false)
    const [loading,setLoading] = useState(false)
    const handleClick = (event) => {
        setOpen(true)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(false)
        setAnchorEl(null);
    };
    const editName = async() =>{
        setLoading(true)
        const uid = localStorage.getItem("uid")
        const org = localStorage.getItem("organization")
        const form = new FormData()
        form.append("organization",org)
        form.append("name",value)
        form.append("userID",uid)
        form.append("chatId",i.chatID)
        const res = await changeChatName(form)
        console.log(res)
        setEdit(false)
        setOpen(false)
        setAnchorEl(null)
        setLoading(false)
    }
    return (
        <Stack>
            {!edit?
            <Stack  className="hover:scale-95 hover:bg-[#EDE7F6] hover:text-[#5E35B1] rounded-md cursor-pointer duration-300 ease-in-out transition-all" direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{mt:0.5,px:1,py:0.5}}>
                <Stack direction={'row'}>
                    {/* <Message /> */}
                    <Typography variant='h5' sx={{ml:0}}>{value}</Typography>
                </Stack>
                <IconButton
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreHoriz />
                </IconButton>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={()=>setEdit(true)}><Edit />&nbsp;&nbsp;&nbsp; Edit</MenuItem>
                    <MenuItem onClick={()=>handleDelete(i.chatID)}><Delete sx={{color:'red'}}/>&nbsp;&nbsp;&nbsp; Delete</MenuItem>
                </Menu>
            </Stack>:<Stack direction={"row"} sx={{mt:2,px:1,py:1}}>
                <TextField value={value} disabled={loading} onChange={(e)=>setValue(e.target.value)} variant='standard' />
                <IconButton
                    onClick={editName}
                    disabled={loading}
                >
                    {!loading?<Done sx={{color:"lightgreen"}} />
                    :
                    <div className=''>
                        <svg className='svg' viewBox="25 25 50 50">
                            <circle className='circle' r="20" cy="50" cx="50"></circle>
                        </svg>
                    </div>}
                </IconButton>
                <IconButton
                    onClick={()=>{
                        setOpen(false)
                        setAnchorEl(null)
                        setEdit(false)
                    }}
                    disabled={loading}
                >
                    <Close sx={{color:"red"}} />
                </IconButton>
            </Stack>}
        </Stack>
    )
}

export default ChatCard