import * as React from 'react';
import Footer from "./Components/footer";
import Nav from "./Components/nav";
import { useEffect, useState } from "react";
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { List, ListItem, ListItemText, Button, Typography, Grid } from "@mui/material";

function HomePage() {
    const [to, setTo] = useState('');

    useEffect(() => {
        const id = localStorage.getItem('uid');
        if (!id) {
            setTo('/login');
        } else {
            setTo('/kb');
        }
    }, []);

    return (
        <div>
            <Nav />
            <Grid container spacing={6} className="mt-20"   
                sx={{
                    justifyContent: "center",
                    alignItems: "left",
                }}>
                <Grid item xs={5} className="flex flex-col items-left">
                    <Typography variant="h2" fontSize={40} align="left">
                        <span className="font-[Caveat] font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">Generative AI&nbsp;</span> powered private ChatBots trained on your <span className="font-[Caveat] font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">DATA&nbsp;</span>
                    </Typography>
                    <List>
                        {["No coding Required", "Create your Knowledge Hub", "Train & Publish AI ChatBot", "Share on your website in minutes"].map((text, index) => (
                            <ListItem key={index}>
                                <ListItemIcon><CheckCircleOutlineIcon /></ListItemIcon>
                                <ListItemText><Typography variant="h4">{text}</Typography></ListItemText>
                            </ListItem>
                        ))}
                    </List>
                    {/* <Button color='secondary' variant='outlined' onClick={to} sx={{mt:2}}>
                        Build AI ChatBot
                    </Button> */}
                </Grid>
                <Grid item xs={5} className="hidden md:flex justify-right">
                    <img 
                        src={require('assets/images/heroimg.png')} 
                        className="rounded-xl w-full" 
                        alt="Hero" 
                        loading="lazy" // Lazy loading for performance
                    />
                </Grid>
            </Grid>

            <Grid container spacing={5} className="mt-2"   
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <Grid item xs={5} className="hidden md:flex items-left">
                    <img 
                        src={require('assets/images/kb4.jpeg')} 
                        className="rounded-xl w-[90%] h-auto" 
                        alt="Knowledge Base" 
                        loading="lazy" // Lazy loading for performance
                    />
                </Grid>
                <Grid item xs={5} className="flex flex-col items-right">
                    <Typography variant="h2" fontSize={40}>
                        Create your powerful <span className="font-[Caveat] font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">Knowledge Hub&nbsp;</span> by uploading 
                    </Typography>
                    <List>
                        {["Multiple File Types", "Media Files", "Custom Data Entry", "Websites", "Databases", "Calendar Events"].map((text, index) => (
                            <ListItem key={index}>
                                <ListItemIcon><CheckCircleOutlineIcon /></ListItemIcon>
                                <ListItemText><Typography variant="h4">{text}</Typography></ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>

            <Grid container spacing={5} className="mt-2"   
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <Grid item xs={5} className="flex flex-col items-left">
                    <Typography variant="h2" fontSize={40} align="left">
                        Build an <span className="font-[Caveat] font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">AI ChatBot&nbsp;</span> in minutes.
                    </Typography>
                    <List>
                        {["Train your AI model with your Knowledge Hub", "Test the AI model", "Customize your AI ChatBot", "Share on your website"].map((text, index) => (
                            <ListItem key={index}>
                                <ListItemIcon><CheckCircleOutlineIcon /></ListItemIcon>
                                <ListItemText><Typography variant="h4">{text}</Typography></ListItemText>
                            </ListItem>
                        ))}
                    </List>
                    {/* <Button color='secondary' variant='outlined' onClick={to}>
                        Build AI ChatBot
                    </Button> */}
                </Grid>
                <Grid item xs={5} className="hidden md:flex justify-right">
                    <img 
                        src={require('assets/images/kb2.jpeg')} 
                        className="rounded-xl" 
                        alt="Knowledge Hub Image" 
                        loading="lazy" // Lazy loading for performance
                    />
                </Grid>
            </Grid>
            <Footer />
        </div>
    );
}

export default HomePage;