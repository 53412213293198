import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import getUserInfo, { addkbtext, getKbByName, getMemberRole } from "services/app.services";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, Select, MenuItem, TextField, Typography, IconButton } from "@mui/material";
import MainCard from 'ui-component/cards/MainCard';
import { Stack } from "@mui/system";
import { Close } from "@mui/icons-material";

function TextArea({close,getKbData,fetchKbNameTop}) {
    const [text,setText] = useState('')
    const [count,setCount] = useState(0)
    const [fileName,setFileName] = useState("")
    const [loading,setLoading] = useState(false)
    const [bot,setBot] = useState('')
    const [uname,setUname] = useState('')
    const [uid,setUid] = useState()
    const [kbname,setKbName] = useState([]);
    const [val,setVal] = useState("")
    const nav = useNavigate()
    const [access,setAccess] = useState(false)
    const [kb_id,setKb_Id] = useState("")

    const fetchKbName = async() => {
        const uid = localStorage.getItem("uid");
        setUid(uid)
        const form = new FormData()
        const user = await getUserInfo(uid)
        form.append("email", user?.email)
        const organization = localStorage.getItem("organization")
        form.append("organization",organization)
        const data = await getKbByName(form)
        setKbName(data.kb)
        setUname(user?.uname)
        const form2 = new FormData()
        // const user = JSON.parse(localStorage.getItem("user"))
        form2.append("email",user?.email)
        const role = await getMemberRole(form2)
        if(role?.role?.role === "Admin"){
          setAccess(true)
        }else{
          setAccess(false)
        }
    }

    useEffect(()=>{
        fetchKbName()
    },[])
    const handleTextChange = (e) => {
        setText(e.target.value);
        // countWords(text)
        setCount(text.length);
    };
    const handleSubmit = async() =>{
        
        setLoading(true);
        if(text === ""){
            setLoading(false)
            return  toast.error("Enter the data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if(fileName === ""){
            setLoading(false)
            return  toast.error("Enter the file name", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if(val === true && bot === ""){
            setLoading(false)
            return  toast.error("Enter the knowledge hub name to continue", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        const body = new FormData();
        const dateObj = new Date
        const day = dateObj.getDate().toString()
        const month = dateObj.getMonth() + 1
        const year = dateObj.getFullYear().toString()
        body.append("created_id",uid)
        body.append("created_by",uname)
        body.append("filename",fileName)
        if(val !== true){
            body.append("kb_name",val)
        }else if(bot){
            body.append("kb_name",bot)
        }
        body.append("last_modified",`${month.toString()}/${day.toString()}/${year.toString()}`)
        body.append("file_type","data")
        body.append("kbdata","")
        body.append("kb_id",kb_id)
        body.append("data",text)
        const organization = localStorage.getItem("organization")
        body.append("organization",organization)
        const res = await addkbtext(body)
        if(res){
            setText("")
            setBot("")
            close(false)
            nav('/kb')
            setLoading(false)
        }
        getKbData()
        fetchKbNameTop()
        close(false)
        setLoading(false)
    }
    const countWords = (text) => {
        const wordsArray = text.trim().split(/\s+/);
        return setCount(wordsArray.length)
    };
    return ( 
        <Stack sx={{ mt: -5, overflow: 'hidden'}}>
            <div className="flex justify-between w-full items-center">
                <Typography variant="h3">Capture data to your Knowledge Hub</Typography>
                <IconButton onClick={()=>close(false)}>
                    <Close />
                </IconButton>
            </div>
            <Stack justifyContent={'center'} alignItems={'center'}>
                <Stack sx={{width: '100%',mt:2}}>
                    <MainCard>          
                        <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data Here</label>
                        <textarea disabled={loading} id="message" rows="4" className="block p-2.5 h-56 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Data to be trained" onChange={handleTextChange} value={text}></textarea>
                        <div className="border-2 mt-2 p-2 rounded-xl">
                        <div className="flex mt-3 flex-col justify-center">
                            <label className="label">File Name</label>
                            <TextField
                                variant="outlined"
                                label="Enter File Name"
                                color="secondary"
                                onChange={(e)=>setFileName(e.target.value)}
                                fullWidth
                                disabled={loading}
                                sx={{mt:2,mb:2}}
                            />
                            <label className="label">Knowledge Hub </label>
                            <Select
                                onChange={(e)=>{
                                    setVal(e.target.value)
                                    if(e.target.value !== true){
                                        const selectedKb = kbname.find(kb => kb.kb_name === e.target.value);
                                        setKb_Id(selectedKb?.kb_id)
                                    }
                                }}
                                value={val}
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                disabled={loading}
                                >
                                {kbname?.map((i,index)=>(<MenuItem value={i?.kb_name} key={index}>{i?.kb_name}</MenuItem>))}
                                {access&&<MenuItem value={true}>New Knowledge Hub</MenuItem>}
                                </Select>
                                {val === true &&<TextField
                                variant="outlined"
                                label="Enter the Knowledge Hub name"
                                color="secondary"
                                onChange={(e)=>{
                                    setBot(e.target.value)
                                    setKb_Id(e.target.value)
                                }}
                                fullWidth
                                disabled={loading}
                                sx={{mt:2}}
                                />}
                            </div>
                            <div className="mt-2">Included Characters count: <span className="text-gray-600 font-semibold">{text.length}</span></div>
                            <div className="flex mt-2 justify-center">
                                {!loading?<Button
                                    onClick={handleSubmit}
                                    variant="outlined"
                                    color="secondary"
                                    sx={{mt:2}}
                                >
                                    Add to Knowledge Hub
                                </Button>:
                                <Button 
                                    disabled 
                                    variant="outlined"
                                    color="secondary"
                                    sx={{mt:2}}
                                >
                                <CircularProgress color="secondary" />
                                </Button>}
                            </div>
                        </div>
                    </MainCard>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default TextArea;