import dashboard from './dashboard';
import pages from './pages';
import bot_pages from './bot_pages';
import utilities from './utilities';
import getUserInfo, { getMemberRole } from 'services/app.services';
import team_page from './team_page';
import chat_page from './chat_pages';

// ==============================|| MENU ITEMS ||============================== //
const roleitems = []
const roles = ["Admin","Create Teams","Create Knowledge Hub","Only Query Knowledge Hub"]

const uid = localStorage.getItem("uid")
console.log(uid)
const user = getUserInfo(uid).then(async(res)=>{
  console.log(res)   
  const form = new FormData()
  console.log(res?.email)
  form.append("email",res?.email)
  const response = await getMemberRole(form)
  const roles = response?.role?.role?.split(",")
  roles?.map((i)=>{
    console.log("roles:", i)
    if (i === "Admin"){
      return roleitems.push(team_page,bot_pages,chat_page,utilities)
    }
    if (i === "Create Teams"){
      console.log("team")
      roleitems.push(team_page)
    }else if(i === "Create Knowledge Base"){
      console.log("pages")
      roleitems.push(pages)
    }else if(i === "Only Query Knowledge Base"){
      console.log("chat")
      roleitems.push(chat_page)
    }
  })
})

const menuItems = {
  items: [dashboard,team_page,bot_pages,chat_page,utilities]
};

export default menuItems;
