import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase,Tooltip } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import ChatSection from './ChatSection';

// assets
import { IconMenu2 } from '@tabler/icons';

import { useEffect } from 'react';
import getUserInfo, { addkbqa, getAskllyAdmin } from 'services/app.services';
import Home from '@mui/icons-material/Home';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import axios from 'axios';

import HubIcon from '@mui/icons-material/Hub';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ListIcon from '@mui/icons-material/List';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle,showHome }) => {
  const nav = useNavigate()
  const theme = useTheme();

  const [isAsklly,setIsAsklly] = useState(false);

  const getAsklly = async() => {
    try {
      const uid = localStorage.getItem("uid")
      const usr = await getUserInfo(uid)
      const org = localStorage.getItem("organization")
      if(!org){
        const { data } = await axios.post("https://fast.asklly.ai/get/org",{
          email: usr?.email
        })
        const body = {
          email: usr.email,
          organization: data
        }
        const orgn  = await getAskllyAdmin(body)
        setIsAsklly(orgn)
      }else{
        const body = {
          email: usr.email,
          organization: org
        }
        const data  = await getAskllyAdmin(body)
        setIsAsklly(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    getAsklly().then()
    return ()=>{
      setIsAsklly(false)
    }
  },[])

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 270,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            {showHome?<Home stroke={1.5} size="1.3rem" />:<IconMenu2 stroke={1.5} size="1.3rem" />}
          </Avatar>
        </ButtonBase>
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} >
        
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        
      </Box>

      {/* notification & profile */}
      {/* Home */}
      <Box sx={{ ml: 1, mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }} onClick={()=>nav("/welcome")}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            color="inherit"
          >
            
            {<Home stroke={1.5} size="1.3rem" />}
          </Avatar>
        </ButtonBase>
      </Box>

      {/* Available Assistants */}
      <Box sx={{ ml: 1, mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }} onClick={()=>nav("/mybots")}>
          <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              color="inherit"
            >
              <Tooltip placement='bottom' title="Available Assistants" arrow>
                <ListIcon stroke={1.5} size="1.3rem" />
              </Tooltip>
            </Avatar>
        </ButtonBase>
      </Box>


      {/* Create Assistant */}
      <Box sx={{ ml: 1, mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }} onClick={()=>nav("/train")}>
          <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              color="inherit"
            >
              <Tooltip placement='bottom' title="Create Assistant " arrow>
                <SmartToyIcon stroke={1.5} size="1.3rem" />
              </Tooltip>
            </Avatar>
        </ButtonBase>
      </Box>


      {/* Knowledge Hub */}
      <Box sx={{ ml: 1, mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }}  onClick={()=>nav("/kb")}>
          <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              color="inherit"
            >
              <Tooltip placement='bottom' title="Knowledge Hub" arrow>
                <HubIcon stroke={1.5} size="1.3rem" />
              </Tooltip>
            </Avatar>
        </ButtonBase>
      </Box>
      <ChatSection />
      <NotificationSection />
      <ProfileSection isAsklly={isAsklly} />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
