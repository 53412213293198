import * as React from 'react';
import { Typography, TextField } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useState, useEffect } from 'react';
import { getAudits } from 'services/app.services';
import { format } from 'date-fns';
import MaterialReactTable from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';

export default function Audit() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const org = localStorage.getItem("organization")
    const uid = localStorage.getItem("uid")
    getAudits(org, uid).then((res) => setRows(res));
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = rows.filter((row) =>
    row.uname.toLowerCase().includes(search.toLowerCase()) ||
    row.title.toLowerCase().includes(search.toLowerCase()) ||
    row.message.toLowerCase().includes(search.toLowerCase())
  );

  const columns = [
    {
      header: 'Date & Time',
      accessorKey: 'created_at',
      Cell: ({ cell }) => format(new Date(cell.getValue()), 'MM/dd/yyyy hh:mm:ss'),
    },
    {
      header: 'User',
      accessorKey: 'uname',
    },
    {
      header: 'Action',
      accessorKey: 'title',
    },
    {
      header: 'Detailed',
      accessorKey: 'message',
    },
  ];

  const handleRowClick = (row) => {
    const nav = filteredRows[row?.index]
    // window.open(nav?.nav,"_blank");
  };

  return (
    <MainCard>
      <Typography variant="h3" sx={{ mb: 6 }}>
        Activity Log
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        sx={{ mb: 3 }}
        onChange={handleSearch}
      />
      <Stack sx={{ mt: 2, borderRadius: '8px', overflow: 'hidden'}}>
        <MaterialReactTable
          initialState={{
            sorting: [{ id: 'created_at', desc: true }]
          }}
          data={filteredRows}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            borderRadius: '8px', // Adjust the radius as needed
            overflow: 'hidden', // Ensure the corners are rounded
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => handleRowClick(row)
          })}
        />
      </Stack>
    </MainCard>
  );
}