import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TextField, tableCellClasses, IconButton, CircularProgress, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { styled } from '@mui/system';
import { IconArrowLeft } from '@tabler/icons';
import { useNavigate } from 'react-router';
import getUserInfo, { deleteTenant, getAskllyAdmin, getTenants } from 'services/app.services';
import { Delete, Trash2 } from 'lucide-react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        BorderTopRounded: theme.shape.borderRadius
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ViewTenants = () => {
    const nav = useNavigate();

    const [tenants, setTenants] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true); // Loading state
    const [open, setOpen] = useState(false); // Modal open state
    const [tenantToDelete, setTenantToDelete] = useState(null); // Tenant to delete
    const [deleting,setDeleting] = useState(false)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        const uid = localStorage.getItem("uid")
        getUserInfo(uid).then((res)=>{
            const body = {
                email: res.email,
                organization: localStorage.getItem("organization")
            }
            getAskllyAdmin(body).then((res)=>{
                if(!res){
                    nav("/404")
                }
            })
        })

        fetchTenants();
    }, []);

    const fetchTenants = async () => {
        setLoading(true); // Set loading to true before fetching
        try {
            const response = await getTenants();
            setTenants(response);
        } catch (error) {
            console.error('Error fetching tenants:', error);
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDeleteTenant = async () => {
        setDeleting(true)
        if (tenantToDelete) {
            await deleteTenant(tenantToDelete);
            fetchTenants();
            setOpen(false);
            setTenantToDelete(null);
        }
        setDeleting(false)
    };

    const filteredTenants = tenants.filter(tenant => 
        tenant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tenant.team_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tenant.admin_email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <MainCard>
            <div className='mt-4 font-bold text-xl mb-6 flex items-center'>
                <IconButton onClick={()=> nav("/tenants")}>
                    <IconArrowLeft />
                </IconButton>
                <h1>All Tenants</h1>
            </div>
            <TextField
                label="Search Tenants"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <TableContainer>
                {loading ? ( // Show loading spinner while fetching
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <Table>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Tenant Name</StyledTableCell>
                                <StyledTableCell>Enterprise Admin Name</StyledTableCell>
                                <StyledTableCell>Enterprise Admin Email</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>{/* New column for actions */}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTenants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tenant) => (
                                <StyledTableRow key={tenant.id}>
                                    <StyledTableCell>{tenant.name}</StyledTableCell>
                                    <StyledTableCell>{tenant.admin_name}</StyledTableCell>
                                    <StyledTableCell>{tenant.admin_email}</StyledTableCell>
                                    <StyledTableCell>
                                        <Tooltip title="Delete Tenant" arrow>
                                            <IconButton 
                                                variant="contained" 
                                                color="secondary" 
                                                onClick={() => {
                                                    setTenantToDelete(tenant.name);
                                                    setOpen(true);
                                                }} // Open confirmation modal
                                            >
                                                <Trash2 color='red' />
                                            </IconButton>
                                        </Tooltip>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredTenants.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={open} onClose={() => {
                if(deleting){
                    return
                }
                setOpen(false)
            }}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this tenant?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  disabled={loading} onClick={() => setOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={handleDeleteTenant} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
};

export default ViewTenants;
