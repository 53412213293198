import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import FileUploadIcon from '@mui/icons-material/FileUploadSharp';
import AddIcon from '@mui/icons-material/Add';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import getUserInfo, { getBotsName, getKb, getKbByName, getMemberRole, getkb, trainKb } from 'services/app.services';
import MainCard from 'ui-component/cards/MainCard';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Modal,
  TextField,
  Select, 
  MenuItem, 
  Chip
} from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { Stack } from '@mui/system';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { ChevronRight, Close, Home, JoinLeft } from "@mui/icons-material";
import NavComp from 'components/NavComp/NavComp';

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
import { purple } from '@mui/material/colors';
import PDFReader from 'components/pdf/PDFViewer';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

//defining columns outside of the component is fine, is stable
const columns = [
  // {
  //   accessorKey: 'id',
  //   header: 'ID',
  //   size: 10,
  // },
  {
    accessorKey: 'kb_name',
    header: 'Knowledge Hub',
    size: 40,
  },
  {
    accessorKey: 'file_name',
    header: 'Documents',
    size: 120,
  },
  {
    accessorKey: 'created_by',
    header: 'Created By',
    size: 120,
  },
  // {
  //   accessorKey: 'location',
  //   header: 'Location',
  //   size: 300,
  // },
  {
    accessorKey: 'last_modified',
    header: 'Created On',
  },
  {
    accessorKey: 'file_size',
    header: 'Size (mb)',
  },
  {
    accessorKey: 'status',
    header:'Status',
  }
];

// var data = []

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const ManageTrainAI = () => {
  const [data,setData] = useState([]);
  const [row,setRows] = useState({})
  const [showData,setShowData] = useState([])
  const [selectedKbName, setSelectedKbName] = useState(null);
  const [open, setOpen] = useState(false);
  const [create,setCreate] = useState(false)
  const [botname,setBotname] = useState("")
  const [bots,setBots] = useState([])
  const [sel,setSel] = useState()
  const [botNames,setBotNames] = useState([])
  const [openDrawer,setOpenDrawer] = useState(false)
  const [numPages, setNumPages] = useState(null);
  const [fileType,setFileType] = useState()
  const [file,setFile] = useState()
  const [fileName,setFileName] = useState()
  const [docs,setDocs] = useState([])
  const [kbname,setKbName] = useState([]);

  function openFileDrawer(index){
    const row = showData[index]
    console.log(row)
    if(row?.file_name.toLowerCase().includes(".pdf")){
      setFileType("pdf")
    }else if(row?.file_name.toLowerCase().includes(".docx") || row?.file_name.toLowerCase().includes(".doc")){
      setFileType("docx")
    }else if(row?.file_name.toLowerCase().includes(".txt")){
      setFileType("txt")
      fetch(file).then((res)=>res.text().then(d=>{
        console.log(d)
      }))
    }else{
      setFileType("No")
    }
    setFile(row?.location)
    setFileName(row?.file_name)
    setDocs([{uri:row?.location}])
    setOpenDrawer(true)
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const nav = useNavigate()

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCreate(false)
    setBotname('')
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: 2
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  async function getKbData(){
    const uid = localStorage.getItem("uid");
    const usr = await getUserInfo(uid)
    console.log("user",usr)
    const form = new FormData()
    form.append("email",usr.email)
    const organization = localStorage.getItem("organization")
    form.append("organization",organization)
    const resp = await getKb(form)
    console.log(resp?.kb[0])
    if(resp?.kb.length > 1){
      setData(resp?.kb)
      setShowData(resp?.kb);
    }else{
      setData(resp?.kb)
      setShowData(resp?.kb[0]);
    }
  }

  const fetchKbName = async() => {
    const form = new FormData()
    const uid = localStorage.getItem("uid")
    const user = await getUserInfo(uid)
    form.append("email", user?.email)
    const organization = localStorage.getItem("organization")
    form.append("organization",organization)
    const data = await getKbByName(form)
    console.log(data.kb)
    setKbName(data.kb)
  }

  useEffect(()=>{
    const uid = localStorage.getItem("uid");
    const usr = localStorage.getItem("usr")
    console.log(usr)
    fetchKbName()
    getKbData()
    getBotsName(uid).then((res)=>{
      if(res.length !== 0){
        console.log(res)
        setBots(res)
        let names = []
        res?.map((i)=>{
          names.push(i.title)
        })
        console.log(names)
        setBotNames(names)
      }
    }).catch((err) => {
      console.error(err);
    });
  },[])

  useEffect(()=>{
    console.log(row)
    Object.entries(row).map((i)=>{
      console.log(data[i[0]].id)
      console.log(i)
    })
  },[row])

  const HandleTrain = async() => {
    let train = []
    Object.entries(row).map((i)=>{
      train.push(showData[i[0]].id)
    })
    if(train.length > 0){
      setOpen(true)
    }else{
      handleClose()
      return toast.error("Select one or more data set to train", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleKBClick = (kbName) => {
    setSelectedKbName(kbName);
    setShowData(data.filter(i=>i?.kb_name === kbName))
  };

  const closeDrawer = () => {
    setFile(null)
    setFileType(null)
    setOpenDrawer(false)
  }

  const handleClearFilter = () => {
    setSelectedKbName(null);
    setShowData(data)
  };

  const train = async() => {
    let train = []
    Object.entries(row).map((i)=>{
      train.push(data[i[0]].id)
    })
    let eid = null
    const dateObj = new Date
    const day = dateObj.getDate().toString()
    const mon = dateObj.getMonth() + 1
    const month = mon.toString()
    const year = dateObj.getFullYear().toString()
    console.log(train)
    const id = localStorage.getItem('uid')
    if(botname){
      const res = await trainKb(botname,train,id,eid,`${day.toString()}/${month}/${year.toString()}`)
      if(res.ok){
        handleClose()
        console.log(res.res)
        if(!res.eid){
          nav(`/edit/new?id=${res?.res?.id}`)
        }else{
          nav(`/edit/${res.eid}`)
        }
      }
    }else{
      if(sel !== true){
        bots.map((i)=>{
          if(i.title === sel){
            eid = i.id;
          }
        })
      }
      console.log(sel,train,id,eid)
      const res = await trainKb(sel,train,id,eid,`${day.toString()}/${month}/${year.toString()}`)
      if(res.ok){
        handleClose()
        console.log(res.eid)
        if(!res.eid){
          nav(`/edit/new`)
        }else{
          nav(`/edit/${res.eid}`)
        }
      }
    }
  } 

  console.log("Data:",data);

  return (
    <Stack>
      <MainCard>
        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
          {/* <Typography variant="h3" sx={{fontWeight: 'bold'}}>Train Your AI ChatBot</Typography> */}
          <div className="flex justify-center items-center">
              <Link to='/mybots'>
                  <Home color="secondary" />
              </Link>
              <ChevronRight />
              <Typography sx={{fontWeight: 'bold'}}>Create Your AI Chatbot</Typography>
          </div>
          <NavComp />
        </Stack>
        <div className='grid md:grid-cols-8 gap-3 grid-cols-2 mt-5'>
          {kbname?.map((i) => (
            <Chip 
              key={i?.kb_name}
              label={i?.kb_name} 
              sx={{ color: purple[800] }} 
              variant={selectedKbName === i?.kb_name ? "filled" : "outlined"} 
              onClick={() => handleKBClick(i?.kb_name)} 
            />
          ))}
          {selectedKbName && (
            <Chip 
              label="Clear Filter" 
              sx={{ color: 'primary.contrastText', bgcolor: 'error.main', ml: 1 }} 
              onClick={handleClearFilter} 
            />
          )}
        </div>
      </MainCard>
      <Stack sx={{ mt: 2, borderRadius: '8px', overflow: 'hidden'}}>
          <MaterialReactTable
            muiTableBodyRowProps={({ row }) => ({
              onClick: ()=>openFileDrawer(row.index)
            })}
            columns={columns}
            data={showData}
            state={{rowSelection:row}}
            enableRowSelection
            onRowSelectionChange={setRows}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >
                <Button
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={HandleTrain}
                  startIcon={<FileUploadIcon />}
                  variant="outlined"
                  color="secondary"
                  style={{
                    maxWidth: '300px', // Set a maximum width as needed
                    width: '100%', // Make the button take up 100% of the available width
                    margin: 'auto', // Center the button horizontally
                  }}
                >
                  Train AI
                </Button>
              </Box>
            )}
            initialState={{
              sorting: [{ id: 'last_modified', desc: true }]
            }}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 500 }}>
              <Typography variant='h3' sx={{fontWeight: 'semibold',mb:2}}>Enter an AI Assistant name</Typography>
              <Stack>
                <Select
                  onChange={(e)=>setSel(e.target.value)}
                  value={sel}
                  className='mb-5'
                  variant="outlined"
                  color="secondary"
                >
                  <MenuItem value={true}>Create new Assistant</MenuItem>
                  {botNames.map((i)=>(<MenuItem value={i}>{i}</MenuItem>))}
                </Select>
                {sel===true&&<TextField onChange={(e)=>setBotname(e.target.value)} value={botname} variant='outlined' color='secondary' label="Enter assistant name here..." sx={{width:'100%',mb:2}} />}
                <Stack direction={'row'} justifyContent={'flex-end'}>
                  <Button variant='outlined' sx={{width:'30%'}} color='error' onClick={handleClose}>Cancel</Button>
                  <Button variant='outlined' sx={{width:'30%',ml:2}} color='secondary' onClick={train}>Create</Button>
                </Stack>
              </Stack>
            </Box>
          </Modal>
        </Stack>
      <PDFReader close={closeDrawer} file={file} openDrawer={openDrawer} fileName={fileName} />
    </Stack>
  );
};

export default ManageTrainAI;