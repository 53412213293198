import { IconUser,IconSettings } from "@tabler/icons"

const icons = {
    IconUser, IconSettings
}

const team_page = {
    id: 'Teams',
    title: 'Manage Access',
    // caption: 'Manage your teams',
    type: 'group',
    children: [
        {
            id: 'Teams',
            title: 'Teams',
            type: 'item',
            url: '/teams',
            icon: icons.IconUser
        },
        {
            id: 'Accounts',
            title: 'Account Settings',
            type: 'item',
            url: '/profile',
            icon: icons.IconSettings
        }
    ]
}

export default team_page