import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { getDownloadURL, ref,uploadBytes } from 'firebase/storage'
import { storage } from "config/firebase.config";
import copy from 'copy-to-clipboard'
import { createApi,deleteApi,fetchbotapi } from "services/app.services";
import Loader from "components/Loader";
import {toast} from 'react-toastify'
import send from 'assets/images/send2.png'
import { useEffect } from "react";
import { Button, Checkbox, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, MoreVert } from "@mui/icons-material";
import Modal from '@mui/material/Modal';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from 'react-router-dom';
import { IconTrash } from "@tabler/icons";
import { Box } from "@mui/system";
import notFound from "assets/images/404.png";
import deleteImg from "assets/images/Inbox cleanup-rafiki.png"
import { X, Send } from 'lucide-react';

function CustomizeBot() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { id } = useParams()
    const idd = queryParams.get('id')
    const [loading,setLoading] = useState(false)
    const [trainingFiles,setTrainingFiles] = useState([])
    const [settings,setSettings] = useState({
        title:'',
        initial:'',
        color:'#f97316',
        textColor:'#ffffff'
    })
    const [file,setFile] = useState(null)
    const [iid,setIid] = useState(0)
    const [btnText,setBtnText] = useState("Copy to clipboard")
    const [url,seturl] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [kbOpen,setkbOpen] = useState(false)
    const [openDelete,setOpenDelete] = useState(false)
    const [mainBtnText,setMainBtnText] = useState("Create AI Assistant")
    const [isok,setIsOk] = useState(false)
    const handleChange = (event) =>{
        setSettings({...settings,[event.target.name]:event.target.value})
    }
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          setFile(selectedFile);
        }
    };
    const [api,setApi] = useState("");
    useEffect(()=>{
        if(queryParams.get('id')){
            setIid(queryParams.get('id'))
        }
        const uid = localStorage.getItem('uid')
        if(id === 'new'){
            fetchbotapi(idd).then((res)=>{
                if(res.ok){
                    setSettings({title:res?.data?.botname,initial:res?.data?.initial,color:res?.data?.color,textColor:res?.data?.textcolor})
                    seturl(res?.data?.image === "null"?null:res?.data?.image)
                    setApi(res?.data?.apikey)
                    setTrainingFiles(res.kb)
                }else{
                    setSettings(null)
                }
            })
        }else{
            setMainBtnText("Update AI ChatBot")
            fetchbotapi(id).then((res)=>{
                if(res.ok){
                    setSettings({title:res?.data?.title,initial:res?.data?.initial,color:res?.data?.color,textColor:res?.data?.textcolor})
                    seturl(res?.data?.image === "null"?null:res?.data?.image)
                    setApi(res?.data?.apikey)
                    setTrainingFiles(res.kb)
                }else{
                    setSettings(null)
                }
            })
        }
    },[])
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const script = `<script src="https://cdn.jsdelivr.net/npm/asklly_windowed_chat/dist/index.min.js"></script>`
    const nav = useNavigate()
    const closeModal = () =>{
        setIsOpen(false);
        nav('/mybots')
    }
    const closeKbModel = () =>{
        setkbOpen(false)
    }
    const copyy = () => {
        const copyText1 = `
            <script>
                window.chatx = {
                    chatbotKey: "${api}"
                }
            </script>
            ${script}
        `;
        copy(copyText1);
        setBtnText("Copied");
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!api){
            if (settings.title === '') {
              setLoading(false);
              return toast.error("All fields Required", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
        }
        setLoading(true);
        const form = new FormData()
        const uid = localStorage.getItem("uid")
        const dateObj = new Date
        const day = dateObj.getDate().toString()
        const mon = dateObj.getMonth() + 1
        const month = mon.toString()
        const year = dateObj.getFullYear().toString()
        try {
            let snap;
            if(file){
                const s = ref(storage, `images/${file.name}`);
                await uploadBytes(s, file);
                snap = await getDownloadURL(s);
                seturl(snap);
                form.append("image",snap)
            }else if(url){
                form.append("image",url)
            }else{
                form.append("image",url)
            }
            const initial = settings.initial || "Hello There! how can i help you today"
            const org = localStorage.getItem("organization")
            form.append("organization",org)
            form.append("color",settings.color)
            form.append("textcolor",settings.textColor)
            form.append("title",settings.title)
            form.append("initial",initial)
            form.append("apikey",api ?api:"no")
            form.append("id",id === "new" ? idd:id)
            form.append("uid",uid)
            form.append("created_at",`${day.toString()}/${month}/${year.toString()}`)
            const res = await createApi(form);
            if (res) {
                setApi(res);
                setIsOpen(true);
            }
        } catch (error) {
          console.error("Error:", error);
          // Handle errors here
        } finally {
          setLoading(false);
        }
    }   
    
    const handleDeleteApi = async() => {
        try {
            if(isok){
                const form = new FormData()
                const org = localStorage.getItem("organization")
                form.append("organization",org)
                form.append("id",id)
                const uid = localStorage.getItem("uid")
                form.append("uid",uid)
                const res = await deleteApi(form)
                setOpenDelete(false)
                nav("/mybots")
            }else{
                toast.error("Please check the box to continue", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error:", error);
        }finally {
            setLoading(false);
        }
    }

    const navBack = () => {
        nav("/mybots")
    }

    return (  
        <div className="h-full w-full">
            {settings ? <div className="md:p-7 flex md:flex-row flex-col md:justify-around justify-center items-center">
                <div className="md:w-1/2">
                    <div className="flex justify-between">
                        <div className="">
                            <h1 className="font-bold text-xl">AI Assistant Interface</h1>
                            <p>Design your assistant inferface</p>
                        </div>
                        <div className="flex items-center">
                            <Tooltip title="Delete">
                                <IconButton onClick={()=>setOpenDelete(true)}>
                                    <IconTrash className="text-red-500 cursor-pointer" />
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        width: '30ch',
                                    },
                                }}
                                >
                                <MenuItem onClick={api?()=>setIsOpen(true):()=>toast.error("Save the bot settings get get api", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                })}>
                                    Add/Embed your AI Assistant
                                </MenuItem>
                                <MenuItem 
                                    disabled={!trainingFiles}
                                    onClick={()=>{
                                        setkbOpen(true)
                                    }}
                                    >
                                    Knowledge Hub used in Assistant
                                </MenuItem>
                            </Menu>
                        </div>
                </div>
                    <form className="mt-6 w-full" onSubmit={handleSubmit}>
                        <label className="label">Profile Name</label>
                        <input
                            autoComplete="off"
                            placeholder="Enter an Title"
                            name="title"
                            id="Email"
                            className="input w-[70%] mt-2 mr-2"
                            type="text"
                            onChange={handleChange}
                            value={settings.title}
                        />
                        <label className="label mt-6">Initial Message</label>
                        <input
                            autoComplete="off"
                            placeholder="Enter an Initial Message"
                            name="initial"
                            id="Email"
                            className="input w-[70%] mt-2 mr-2"
                            type="text"
                            onChange={handleChange}
                            value={settings.initial}
                        />
                        <label className="label mt-6">Upload Photo</label>
                        <input type="file" name="file" id="file-input" class="block border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400
                            file:bg-transparent file:border-0
                            file:bg-gray-200 w-[70%] file:mr-4
                            file:py-3 file:px-4
                            dark:file:bg-gray-700 dark:file:text-gray-400"
                            accept=".jpeg,.jpg,.png,.webp"
                            onChange={handleFileChange}
                        />
                        <label className="label mt-6">Choose a User Chat Background Colour</label>
                        <input id="nativeColorPicker1" type="color" name="color" onChange={handleChange} className="mt-2 cursor-pointer" value={`${settings.color}`}/>
                        <label className="label mt-6">Choose a User Chat Text Colour</label>
                        <input id="nativeColorPicker1" type="color" name="textColor" onChange={handleChange} className="mt-2 cursor-pointer" value={`${settings.textColor}`}/>
                        {!loading?<button type="submit" className="bg-black w-full h-10 rounded-xl mt-4 text-white font-semibold hover:scale-105 transition-all flex justify-center items-center duration-200 ease-in-out">{mainBtnText}</button>:<button disabled className="bg-black cursor-not-allowed w-full h-10 rounded-xl mt-4 text-white font-semibold hover:scale-105 transition-all flex justify-center items-center duration-200 ease-in-out"><Loader /></button>}
                    </form>
                </div>
                <div>
                    <div className="w-80 h-[30rem] bg-white rounded-lg shadow-xl overflow-hidden flex flex-col">
                        <div className="bg-black text-white p-4 flex justify-between items-center">
                            <div className="flex items-center gap-3">
                                {!file&&url?<img accept=".png,.jpg,.jpeg,.svg,.gif" src={url} className="h-10 w-10 rounded-full" />:file&& <img accept=".png,.jpg,.jpeg,.svg,.gif" src={URL.createObjectURL(file)} className="h-10 w-10 rounded-full" />}
                                <h1 className="font-bold text-xl ml-3">{settings.title || 'Profile name'}</h1>
                            </div>
                            <button className="text-white hover:opacity-80">
                                <X size={24} />
                            </button>
                        </div>

                        <div className="flex-grow overflow-y-auto p-4 space-y-4">
                            <div className="flex justify-start">
                                <div className="max-w-[70%] p-3 rounded-lg bg-gray-200 text-black rounded-bl-none">
                                    {settings.initial || 'Hello there how can i help you'}
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <div className="max-w-[70%] p-3 rounded-lg rounded-br-none" 
                                    style={{
                                        backgroundColor: settings.color,
                                        color: settings.textColor
                                    }}
                                >
                                    Hi! I have a question 
                                </div>
                            </div>
                            <div className="flex justify-start">
                                <div className="max-w-[70%] p-3 rounded-lg bg-gray-200 text-black rounded-bl-none">
                                    Of course! I'd be happy to help.
                                </div>
                            </div>
                        </div>

                        <div className="p-4 border-t border-gray-200 flex items-center">
                            <input
                                type="text"
                                placeholder="Type your message..."
                                disabled
                                className="flex-grow p-2 border bg-white text-black border-gray-300 rounded-lg mr-2 focus:outline-none focus:ring-2 focus:ring-black"
                            />
                            <button className="p-2 bg-black text-white rounded-full hover:bg-gray-800">
                                <Send size={20} />
                            </button>
                        </div>
                    </div>
                    <div className="w-full flex justify-end items-center">
                        <button className="bg-black h-10 mt-10 text-white w-10 rounded-full">ai</button>
                    </div>
                </div>
                <Modal
                    open={isOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="h-full w-full flex justify-center items-center">
                        <div className="md:p-10 p-3 rounded-md bg-white flex-col justify-center items-center">
                            <h1 className="font-bold text-xl">Script Code</h1>
                            <div className="mockup-code">
                                <pre data-prefix="4"><code>{'<script>'}</code></pre>
                                <pre data-prefix="5"><code>{'   window.chatx = {'}</code></pre>
                                <pre data-prefix="6"><code>{`        chatbotKey: "${api}"`}</code></pre>
                                <pre data-prefix="7"><code>{'   }'}</code></pre>
                                <pre data-prefix="8"><code>{'</script>'}</code></pre>
                                <pre data-prefix="9"><code>{script}</code></pre>
                            </div>
                            <button className="px-6 py-3 mt-2 text-white bg-black hover:scale-110 transition-all ease-in-out duration-300 rounded-md cursor-pointer" onClick={copyy}>{btnText}</button>
                            <a className="px-6 py-3 mt-2 text-white bg-red-400 ml-2 hover:scale-110 transition-all ease-in-out duration-300 rounded-md cursor-pointer" href="javascript:void(0);" onClick={closeModal}>Close</a>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={kbOpen}
                    onClose={closeKbModel}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="h-full w-full flex justify-center items-center">
                        <div className="md:p-10 p-3 rounded-md bg-white flex-col justify-center items-center">
                            <Typography sx={{fontWeight:700,fontSize:20,mb:1}}>Knowledge Hub Used</Typography>
                            <div className="overflow-auto min-h-fit mb-5 max-h-48 w-96">
                            {trainingFiles.map((file, index) => (
                                <div key={index} className="mb-4 last:mb-0 bg-gray-50 rounded-lg p-4 shadow">
                                    <div className="flex items-center mb-2">
                                        <div className="w-2 h-2 bg-blue-500 rounded-full mr-2"></div>
                                        <h3 className="font-semibold text-lg">{file.file_name}</h3>
                                    </div>
                                    <div className="text-sm text-gray-600 mb-2">
                                        <span className="font-medium">Knowledge Hub:</span>
                                        <span className="ml-1">{file.kb_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2 text-sm">
                                        <div>
                                            <span className="font-medium">Created By:</span>
                                            <span className="ml-1">{file.created_by}</span>
                                        </div>
                                        <div>
                                            <span className="font-medium">Status:</span>
                                            <span className="ml-1">{file.status}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                            <Button variant="outlined" color="error" onClick={()=>setkbOpen(false)}>Close</Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={openDelete}
                    onClose={()=>setOpenDelete(false)}
                >
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <div className='bg-white h-[35vh] md:w-[30vw] w-[90%] rounded-md flex flex-col justify-center items-center'>
                            {/* <img src={deleteImg} className='w-40' /> */}
                            <div className='flex flex-col justify-center items-start'>
                                <Typography variant='h3' sx={{}}>Delete Assistant</Typography>
                                <div className='flex items-center mt-2'>
                                    <Checkbox id="ok" color='secondary' value={isok} onClick={() => { setIsOk(!isok); }} />
                                    <label htmlFor="ok" className='font-semibold'>I Understand that deleting items cannot be recovered</label>
                                </div>
                                <div className='flex items-center mt-2'>
                                    <Button color='secondary' sx={{ mr: 2 }} onClick={() => {
                                        setOpenDelete(false);
                                    }}>Cancel</Button>
                                    <Button color="error" variant="outlined" onClick={handleDeleteApi}>Delete</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>:<div className="flex flex-col md:flex-row justify-center items-center md:justify-between p-4 h-full">
                <img src={notFound} className="w-72" />
                <div className="w-full md:w-2/3">
                    <h1 className="text-[#5E35B1] font-semibold text-2xl">Took an Wrong Turn</h1>
                    <p className="mt-3">The bot you are searching for is not found</p>
                    <Button onClick={navBack} variant="outlined" color="secondary" className="mt-4">Nav Back To Bots</Button>
                </div>
            </div>}
        </div>
    );
}

export default CustomizeBot;