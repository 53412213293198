import MainLayout from "layout/MainLayout";
import AddTenants from "../views/Tenants/addTeants";
import ViewTenants from "views/Tenants/viewTenants";

const TenantsRoute = {
    path: "/tenants",
    element: <MainLayout />,
    children: [
        {
            path: "",
            element: <AddTenants />
        },
        {
            path: "view",
            element: <ViewTenants />
        },
    ]
}

export default TenantsRoute