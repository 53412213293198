import React, { useState, useRef } from 'react';
import { PlusCircle, Trash2 } from 'lucide-react';
import { useEffect } from 'react';
import getUserInfo, { addTenant, getAskllyAdmin, getRoles } from 'services/app.services';
import { toast, useToast } from 'react-toastify';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Button, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddTenants = () => {
  const [organizationName, setOrganizationName] = useState('');
  const [logo, setLogo] = useState('');
  const [teamName, setTeamName] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [members, setMembers] = useState([{ email: '', role: [] }]);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [appLoading, setAppLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  const nav = useNavigate()

  useEffect(() => {
    const uid = localStorage.getItem("uid")
    getUserInfo(uid).then((res)=>{
      const body = {
        email: res.email,
        organization: localStorage.getItem("organization")
      }
      getAskllyAdmin(body).then((res)=>{
        setAppLoading(false)
        if(!res){
          nav("/404")
        }
      })
    })
    getRoles().then((res) => setRoles(res?.roles));
  }, []);

  const handleAddMember = () => {
    setMembers([...members, { email: '', role: [] }]);
  };

  const handleRemoveMember = (index) => {
    const newMembers = members.filter((_, i) => i !== index);
    setMembers(newMembers);
  };

  const handleMemberChange = (index, field, value) => {
    const newMembers = [...members];
    newMembers[index][field] = value;
    setMembers(newMembers);
  };

  const clearField = () => {
    setOrganizationName('');
    setLogo('');
    setTeamName('');
    setAdminName('');
    setAdminEmail('');
    setMembers([{ email: '', role: [] }]);
    nav("/tenants/view")
  }

  const handleFileChange = (e) => {
    setLogo(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result); // Set the image preview URL
    };
    reader.readAsDataURL(e.target.files[0]); // Read the file as a data URL
  };

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };

  const handleSubmit = async () => {
    if (!organizationName || !logo) {
      toast.error("All Field required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    const isValidEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };

    if (!isValidEmail(adminEmail)) {
      toast.error("Invalid admin email address", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    for (const member of members) {
        if (!member.email || !member.role.length) {
            toast.error("Member email and role are required", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        if (!isValidEmail(member.email)) {
            toast.error(`Invalid email address for member: ${member.email}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
    }
    const formData = new FormData();
    formData.append('tenant_name', organizationName);
    formData.append('tenant_logo', logo);
    formData.append('team_name', teamName);
    formData.append('team_email', adminEmail);
    formData.append('admin_name', adminName);
    formData.append('members', JSON.stringify(members.map(member => ({
      email: member.email,
      role: member.role.join(",")
    }))));

    try {
      setLoading(true)
      const data = await addTenant(formData, clearField)
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <MainCard>
      {appLoading ? (
        <div className="flex justify-center items-center h-[40vh]">
          <CircularProgress color='primary' />
        </div>
      ) : (
        <div>
          <div className='flex justify-between items-center'>
            <h1 className="text-3xl font-bold mb-6 text-gray-800">New Tenant</h1>
            <Link to={"/tenants/view"} className='text-[#5E35B1]'>View All Tenants</Link>
          </div>
          
          <div className="justify-between items-center">
            <TextField
              id="name-input"
              name="organization_name"
              label="Organization Name"
              type="text"
              variant="outlined"
              margin="normal"
              onChange={(e) => setOrganizationName(e.target.value)}
              fullWidth
              color='secondary'
              value={organizationName}
            />
            <input
              type="file"
              accept="image/*"
              className="hidden" // Hide the default file input
              ref={fileInputRef}
              onChange={handleFileChange}
              required
            />
            <div className='flex justify-start items-center'>
              <Button
                color='secondary' variant='outlined'
                onClick={handleButtonClick}
              >
                Upload Logo
              </Button>
              {imagePreview && (
                <div style={{ marginLeft: '20px' }}>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                      border: '1px solid #ddd',
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-700">Team Information</h2>
          <div className="space-y-4">
            <TextField
              id="team-name-input"
              name="Team_Name"
              label="Team Name"
              type="text"
              variant="outlined"
              margin="normal"
              onChange={(e) => setTeamName(e.target.value)}
              fullWidth
              color='secondary'
              value={teamName}
            />
            <TextField
              id="admin-name-input"
              name="Enterprise_Admin_Name"
              label="Enterprise Admin Name"
              type="text"
              variant="outlined"
              margin="normal"
              onChange={(e) => setAdminName(e.target.value)}
              fullWidth
              color='secondary'
              value={adminName}
            />
            <TextField
              id="admin-email-input"
              name="Enterprise_Admin_Email"
              label="Enterprise Admin Email"
              type="text"
              variant="outlined"
              margin="normal"
              onChange={(e) => setAdminEmail(e.target.value)}
              fullWidth
              color='secondary'
              value={adminEmail}
            />
          </div>

          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-700">Members</h2>
          {members.map((member, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <TextField
                id="member-email-input"
                name="Member_Email"
                label="Member e-mail"
                type="text"
                variant="outlined"
                margin="normal"
                onChange={(e) => handleMemberChange(index, 'email', e.target.value)}
                color='secondary'
                value={member.email}
                fullWidth
              />
              <FormControl className='w-[40%]' size='medium'>
                <Select
                  labelId="role-label"
                  id="role"
                  multiple
                  color='secondary'
                  value={member.role}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    const selectedRoles = typeof value === 'string' ? value.split(',') : value;
                    handleMemberChange(index, 'role', selectedRoles);
                  }}
                  MenuProps={MenuProps}
                >
                  {roles.map((role) => (
                    <MenuItem
                      key={role.roles}
                      value={role.roles}
                    >
                      {role.roles}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <button
                onClick={() => handleRemoveMember(index)}
                className="p-2 text-red-600 hover:text-red-800"
              >
                <Trash2 size={20} />
              </button>
            </div>
          ))}
          <button
            onClick={handleAddMember}
            className="flex items-center text-blue-600 hover:text-blue-800 mt-2"
          >
            <PlusCircle size={20} className="mr-2" />
            Add Member
          </button>

          {/* <button
            onClick={handleSubmit}
            disabled={loading}
            className="mt-8 w-full bg-[#5E35B1] text-white py-2 px-4 rounded hover:bg-[#6737c6] transition duration-300"
          >
            {loading ? <CircularProgress color='primary' /> : "Submit"}
          </button> */}
          <Button
              color='secondary' variant='outlined'
              disabled={loading}
              onClick={handleSubmit}
              className="mt-8"
            >
              {loading ? <CircularProgress color='primary' /> : "Create new tenant"}
            </Button>
        </div>
      )}
    </MainCard>
  );
};

export default AddTenants;