import { useEffect, createContext } from "react";

const AccessContext = createContext(null)

export function AccessProvider({children}){
    useEffect(()=>{
        console.log("Hello from context")
    },[])
    
    return(
        <AccessContext.Provider>
            {children}
        </AccessContext.Provider>
    )
}